import { OpenSearchResult } from "@genericTypes/sharedTypes";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";
import { NextApiRequest } from "next";

interface Response {
    took: number;
    timed_out: boolean;
    _shards: {
        total: number;
        successful: number;
        skipped: number;
        failed: number;
    };
    hits: {
        total: {
            value: number;
            relation: string;
        };
        max_score: number;
        hits: OpenSearchResult[];
    };
}

export const getSearchResult = async ({
    searchQuery,
    req,
    offset = 0,
}: {
    searchQuery: string;
    req?: NextApiRequest;
    offset?: number;
}): Promise<{ data: OpenSearchResult[] | null; error: Error | null }> => {
    const headers: { [x: string]: string } = {
        "X-DOMAIN-NAME": `${req ? req?.headers?.host ?? "" : ""}`,
        ...(req
            ? {
                  authorization: process.env.SEARCH_API_KEY ?? "",
                  backendprocess: "sell-valid-not-sold",
              }
            : {}),
    };

    const config: AxiosRequestConfig = {
        method: req ? "post" : "get",
        headers,
        url: req
            ? `${process.env.BACKEND_URL as string}/es/content-${
                  process.env.NEXT_PUBLIC_DEFAULT_LANG ?? "en"
              }-*/_search`
            : `/api/content-search?searchQuery=${searchQuery}&offset=${offset}`,
        data: req
            ? {
                  size: 10,
                  from: offset,
                  _source: {
                      includes: [
                          "raw.title",
                          "url",
                          "modelType",
                          "raw.overview",
                          "raw.body",
                          "weight",
                      ],
                  },
                  sort: ["_score", { weight: "desc" }],
                  query: {
                      function_score: {
                          query: {
                              bool: {
                                  must: [
                                      {
                                          match_bool_prefix: {
                                              "raw.title": {
                                                  query: searchQuery,
                                                  //   fuzziness: "AUTO:3,10",
                                                  fuzziness: "AUTO",
                                                  operator: "and",
                                              },
                                          },
                                      },
                                      {
                                          exists: {
                                              field: "url",
                                          },
                                      },
                                  ],
                                  must_not: [
                                      {
                                          match: {
                                              isDeleted: true,
                                          },
                                      },
                                  ],
                              },
                          },
                          boost: "5",
                          functions: [
                              {
                                  filter: {
                                      match: {
                                          modelType: "offers",
                                      },
                                  },
                                  weight: 15,
                              },
                              {
                                  filter: {
                                      match: {
                                          modelType: "categories",
                                      },
                                  },
                                  weight: 20,
                              },
                              {
                                  filter: {
                                      match: {
                                          modelType: "comparison",
                                      },
                                  },
                                  weight: 10,
                              },
                          ],
                          boost_mode: "replace",
                      },
                  },
                  highlight: {
                      fields: {
                          "raw.title": {
                              highlight_query: {
                                  match: {
                                      "raw.title": {
                                          query: searchQuery,
                                          fuzziness: "AUTO",
                                          operator: "and",
                                      },
                                  },
                              },
                              boundary_chars: "\n",
                              fragment_size: 300,
                              boundary_scanner: "sentence",
                              boundary_max_scan: 1,
                          },
                      },
                  },
              }
            : null,
    };

    if (req) {
        const { data: searchQueryResponse, error } = await apiHandler<Response>(
            () => HTTP.server(config),
        );
        return { data: searchQueryResponse?.hits?.hits ?? null, error };
    } else {
        const { data: searchQueryResponse, error } = await apiHandler<{
            data: OpenSearchResult[];
        }>(() => HTTP.client(config));

        return { data: searchQueryResponse?.data ?? null, error };
    }
};
