import React, { ReactElement } from "react";
import Link from "@components/shared/linkNoPrefetch";
import { getUrl } from "@helpers/switchLanguage";
import { Languages } from "@genericTypes/sharedTypes";
import { useQuery } from "@tanstack/react-query";
import { GQLUrls } from "@gql/types/gql";

export default function LanguageListItem({
    lang,
    label,
    className,
}: {
    lang: string;
    label: string;
    className?: string;
}): ReactElement {
    const { data: availableUrls } = useQuery<GQLUrls[]>({
        queryKey: ["availableUrls"],
    });

    const resolveUrl = (): string => {
        if (availableUrls && availableUrls.length) {
            return (
                (availableUrls.find((item) => item.language === lang)
                    ?.url as string) ?? "/blog/category"
            );
        }
        return "/";
    };

    return (
        <li>
            <Link
                href={getUrl(lang as Languages, resolveUrl())}
                className={
                    className
                        ? className
                        : `block m-1 lg1048:font-normal text-white hover:no-underline  text-center ${
                              process.env.NEXT_PUBLIC_DEFAULT_LANG === lang
                                  ? "text-[#F9BB21] font-bold"
                                  : ""
                          }`
                }
            >
                <span> {label}</span>
            </Link>
        </li>
    );
}
