import { getFromStorage, saveToStorage } from "./storageHelper";

export const checkVersion = (): void => {
    // get version from cookie and check if cookie exists and it matches the env variable
    const buildVersionId = getFromStorage("local", "version") ?? "";
    if (!process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA) {
        // local dev env
        return;
    }
    if (buildVersionId !== process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA) {
        localStorage.clear();
        saveToStorage(
            "local",
            "version",
            process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ?? "dev",
        );
        if (buildVersionId) window.location.reload();
    }
};
