import { TranslationsObject } from "@genericTypes/sharedTypes";
import { translate } from "@helpers/translationObjectFormatter";
import { useQuery } from "@tanstack/react-query";
import React from "react";

export default function NoResults({
    color,
    message,
}: {
    color?: string;
    message?: string;
}) {
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    return (
        <div className="text-center text-2xl mx-auto flex flex-col justify-center items-center gap-10 py-20 font-bold text-[#1693f0]">
            <EmptyFolder color={color} />
            {message ??
                translate(translations, "several_general_no_results_found")}
        </div>
    );
}

function EmptyFolder({ color = "#0e2450" }: { color?: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="292"
            height="195"
            fill="none"
            viewBox="0 0 292 195"
        >
            <g clipPath="url(#clip0_2505_6005)">
                <path
                    fill="#29B3FF"
                    d="M198.781 77.803a34.862 34.862 0 013.356-14.964h4.212a31.17 31.17 0 00-3.809 14.964 1.877 1.877 0 01-1.88 1.874c-1.04 0-1.879-.84-1.879-1.874zM190.248 89.475H36.203V34.677h85.599l28.254 28.162h41.213a44.826 44.826 0 00-2.555 14.964 45.032 45.032 0 001.534 11.672zM236.566 183.375V195H36.203v-11.625l1.88 9.751h196.603l1.88-9.751z"
                ></path>
                <path
                    fill={color}
                    d="M248.675 120.595c-.672.229-1.352.443-2.038.638l-10.067 52.21-3.433 17.808H39.641l-3.433-17.808-15.829-82.094h170.419a42.991 42.991 0 01-.545-1.874 42.14 42.14 0 01-.46-1.874H15.828l20.38 105.703.327 1.696h199.708l.327-1.696 14.165-73.466a43.59 43.59 0 01-2.06.757zM291.445 135.002a1.888 1.888 0 01-2.66 0l-24.035-23.957c.462-.421.914-.855 1.357-1.297a43.75 43.75 0 001.303-1.352l24.035 23.957a1.869 1.869 0 010 2.649zM234.055 42.634c1.038 0 1.88.84 1.88 1.874a1.877 1.877 0 01-1.88 1.874c-17.381 0-31.523 14.096-31.523 31.421 0 1.035-.84 1.874-1.88 1.874s-1.879-.84-1.879-1.874c0-19.392 15.827-35.17 35.282-35.17z"
                ></path>
                <path
                    fill={color}
                    d="M281.287 77.802c0 12.325-4.753 23.543-12.53 31.933a46.12 46.12 0 01-2.658 2.651 47.144 47.144 0 01-32.039 12.49c-26.08 0-47.224-21.076-47.224-47.074 0-25.998 21.144-47.073 47.224-47.073a47.219 47.219 0 0118.074 3.572l1.549 1.06a1.88 1.88 0 01-2.268.67 45.222 45.222 0 00-17.355-3.428c-25.003 0-45.344 20.278-45.344 45.2 0 24.921 20.341 45.198 45.344 45.198 11.829 0 22.612-4.536 30.693-11.956a44.944 44.944 0 002.66-2.649c7.442-8.056 11.993-18.803 11.993-30.594 0-11.79-4.446-22.742-12.52-31.184a1.866 1.866 0 01-.188-2.351l1.549 1.059c8.08 8.447 13.04 19.885 13.04 32.476z"
                ></path>
                <path
                    fill={color}
                    d="M283.164 77.803c0 12.447-4.631 24.184-13.079 33.259a50.129 50.129 0 01-2.658 2.651c-9.104 8.421-20.881 13.037-33.369 13.037-13.116 0-25.448-5.092-34.721-14.337-9.276-9.245-14.384-21.536-14.384-34.61 0-13.075 5.108-25.365 14.384-34.61 9.273-9.246 21.605-14.336 34.721-14.336 6.509 0 12.832 1.25 18.794 3.712a1.871 1.871 0 01.828 2.792 1.878 1.878 0 01-2.267.67 45.225 45.225 0 00-17.355-3.427c-25.003 0-45.345 20.277-45.345 45.199 0 24.921 20.342 45.199 45.345 45.199 11.828 0 22.611-4.537 30.692-11.957a44.911 44.911 0 002.66-2.649c7.443-8.056 11.994-18.803 11.994-30.593s-4.447-22.743-12.521-31.185a1.866 1.866 0 01.065-2.647 1.883 1.883 0 012.658.063c8.743 9.14 13.558 21.133 13.558 33.769z"
                ></path>
                <path
                    fill={color}
                    d="M235.935 44.508a1.877 1.877 0 01-1.88 1.874c-17.381 0-31.523 14.096-31.523 31.421 0 1.035-.84 1.874-1.88 1.874s-1.879-.84-1.879-1.874c0-19.392 15.827-35.17 35.282-35.17 1.038 0 1.88.84 1.88 1.875zM156.31 52.733H99.25c-14.587 0-26.452-11.828-26.452-26.367 0-.398.01-.794.027-1.189H24.38c-1.038 0-1.88-.839-1.88-1.874 0-1.034.842-1.874 1.88-1.874h48.882C75.585 9.243 86.355 0 99.25 0h19.197c6.963 0 12.631 5.647 12.631 12.59s-5.666 12.59-12.631 12.59h-41.86c-.02.392-.03.79-.03 1.188 0 12.472 10.18 22.619 22.693 22.619h57.06c1.038 0 1.881.84 1.881 1.874a1.878 1.878 0 01-1.881 1.874v-.002zM77.1 21.431h41.347c4.891 0 8.87-3.966 8.87-8.841 0-4.876-3.979-8.842-8.87-8.842H99.25c-10.813 0-19.883 7.578-22.15 17.683zM8.1 27.212c3.9-.053 7.04-1.765 7.011-3.826-.027-2.06-3.212-3.688-7.112-3.636-3.9.052-7.039 1.765-7.011 3.825.028 2.06 3.212 3.689 7.112 3.637z"
                ></path>
                <path
                    fill={color}
                    d="M12.281 18.663c1.293-2.98 1.057-5.948-.527-6.631-1.584-.683-3.917 1.178-5.21 4.158-1.293 2.98-1.057 5.949.528 6.632 1.584.683 3.916-1.179 5.21-4.159z"
                ></path>
                <path
                    fill={color}
                    d="M7.621 22.167c1.516-.823 1.482-3.801-.076-6.652-1.559-2.85-4.05-4.494-5.566-3.671s-1.482 3.801.076 6.652c1.559 2.85 4.05 4.494 5.566 3.671z"
                ></path>
                <path
                    fill={color}
                    d="M4.395 21.99a2.194 2.194 0 00-2.226-2.16A2.193 2.193 0 000 22.05a2.194 2.194 0 002.226 2.16 2.193 2.193 0 002.168-2.22h.001zM13.256 28.628a.352.352 0 00.037-.701 7.578 7.578 0 01-3.88-1.668c1.948.694 4.155.645 6.028-.2.177-.08.256-.289.177-.467a.353.353 0 00-.468-.174c-2.266 1.023-5.074.765-7.152-.66L6.36 23.635l1.15 1.616a8.294 8.294 0 005.7 3.373l.047.002v.002zM176.183 52.733h-8.928c-1.038 0-1.88-.839-1.88-1.874 0-1.034.842-1.874 1.88-1.874h8.928c1.038 0 1.88.84 1.88 1.874a1.878 1.878 0 01-1.88 1.874z"
                ></path>
                <path
                    fill="#29B3FF"
                    d="M243.272 62.205c.77-2.723 3.155-4.117 5.739-3.392 2.584.726 3.813 3.136 3.044 5.86-.729 2.575-2.989 4.085-5.719 3.317-2.583-.725-3.792-3.21-3.064-5.785zm4.537-9.844l.394-1.953c.681-4.097 3.238-8.065 8.176-11.444 4.424-2.965 7.082-5.317 8.019-8.629 1.061-3.752-.593-6.918-5.224-8.298-2.658-.746-5.859-.693-8.1.186l-.46-5.133c2.935-1.082 7.475-1.076 11.387.023 8.49 2.385 10.849 8.688 9.27 14.28-1.415 5.002-5.24 7.82-9.97 11.018-4.33 2.915-6.425 5.82-7.266 9.634l-.467 1.933-5.759-1.619v.002z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_2505_6005">
                    <path fill={color} d="M0 0H292V195H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}
