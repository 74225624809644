import {
    AllFieldsDependency,
    FieldDependencyPayload,
    AllForms,
    FormErrors,
    GenericField,
    IField,
    SelectState,
    AllConditionalLogicFieldsByForm,
} from "@genericTypes/form-store-types";
import { getDataDependency } from "@helpers/formHelpers/get-data-dependency";
import { getFromStorage, saveToStorage } from "@helpers/storageHelper";
import { AllConditionalLogicFieldsAtom } from "@store/formStore";
import { Setter } from "jotai";
import { DomainForm } from "pages/api/domainForm";

const STORAGE_TYPE = "local";

const SHARED_FIELDS_CODENAME = [
    "firstName",
    "lastName",
    "phoneNumber",
    "email",
];

export const addUpdateFieldForm = (
    form: AllForms,
    allFieldsDependency: AllFieldsDependency,
    selectState: SelectState,
    allConditionalLogicFields: AllConditionalLogicFieldsByForm,
    set: Setter,
    data: IField,
): AllForms => {
    if (data?.codeName) {
        const dependenciesWithoutDuplicates =
            removeDuplicates(allFieldsDependency);
        // check if codeName exists
        if (form[data.formId]) {
            // (1.0) check if this form exists inside form object
            if (form[data.formId].data?.[data.codeName]) {
                // (2.0) check if codeName exists inside formId object
                form[data.formId].data[data.codeName].value = data.value;
                form[data.formId].data[data.codeName].id = data.id;
                // for select
                if (data.displayLabel) {
                    form[data.formId].data[data.codeName].displayLabel =
                        data.displayLabel;
                }
                if (allConditionalLogicFields) {
                    const res = checkConditionalFields(
                        allConditionalLogicFields,
                        data,
                        set,
                    );
                    // clear data if field is removed (field dependency)
                    if (!res.show) {
                        if (form[data.formId].data[res.codeName])
                            form[data.formId].data[res.codeName].value = "";
                    }
                }
                // to clear values for data dependency
                if (data?.type === "select") {
                    let fields = [
                        ...Object.values(dependenciesWithoutDuplicates),
                    ];
                    if (fields.length === 0) {
                        fields = Object.values(
                            JSON.parse(
                                getFromStorage("local", "dependencies").length
                                    ? getFromStorage("local", "dependencies")
                                    : "[]",
                            ) as AllFieldsDependency,
                        );
                    }
                    fields = fields.map((obj) => {
                        obj.dependency = obj.dependency.filter(
                            (dep, index, self) => {
                                return (
                                    index ===
                                    self.findIndex(
                                        (d) => d.dependsOn === dep.dependsOn,
                                    )
                                );
                            },
                        );
                        return obj;
                    });
                    let updatedDependencies = {};
                    for (let i = 0; i < fields.length; i++) {
                        const dependencies = [...fields[i].dependency];
                        for (let j = 0; j < dependencies.length; j++) {
                            if (
                                dependencies[j].dependsOn === data.codeName &&
                                form[data.formId].data[fields[i].codeName]
                            ) {
                                form[data.formId].data[
                                    fields[i].codeName
                                ].value = "";
                                form[data.formId].data[
                                    fields[i].codeName
                                ].displayLabel = "";
                                form[data.formId].data[fields[i].codeName].id =
                                    "";
                                if (
                                    selectState &&
                                    selectState[fields[i].codeName]
                                )
                                    selectState[fields[i].codeName].value = "";
                                dependencies[j].id = data.id;
                                dependencies[j].value = data.value as string;
                                fields[i].options = [];
                            } else if (
                                dependencies[j].dependsOn === data.codeName
                            ) {
                                dependencies[j].id = data.id;
                            }
                        }
                        if (fields.length) {
                            updatedDependencies = {
                                ...updatedDependencies,
                                [fields[i].codeName]: fields[i],
                            };
                        }
                    }
                    saveToStorage(
                        "local",
                        "dependencies",
                        JSON.stringify(updatedDependencies),
                    );
                }
            } else {
                // (2.1) if codeName doesn't exists, spread the formId object and add the new formData object
                form[data.formId] = {
                    ...form[data.formId],
                    data: {
                        ...form[data.formId].data,
                        [data.codeName]: { ...data },
                    },
                };
                if (data?.type === "select") {
                    let fields = [
                        ...Object.values(dependenciesWithoutDuplicates),
                    ];
                    if (fields.length === 0) {
                        fields = Object.values(
                            JSON.parse(
                                getFromStorage("local", "dependencies").length
                                    ? getFromStorage("local", "dependencies")
                                    : "[]",
                            ) as AllFieldsDependency,
                        );
                    }
                    fields = fields.map((obj) => {
                        obj.dependency = obj.dependency.filter(
                            (dep, index, self) => {
                                return (
                                    index ===
                                    self.findIndex(
                                        (d) => d.dependsOn === dep.dependsOn,
                                    )
                                );
                            },
                        );
                        return obj;
                    });
                    let updatedDependencies = {};
                    for (let i = 0; i < fields.length; i++) {
                        const dependencies = [...fields[i].dependency];
                        for (let j = 0; j < dependencies.length; j++) {
                            if (
                                dependencies[j].dependsOn === data.codeName &&
                                form[data.formId].data[fields[i].codeName]
                            ) {
                                // dependencies[j].value = data.value as string;
                                form[data.formId].data[
                                    fields[i].codeName
                                ].value = "";
                                form[data.formId].data[
                                    fields[i].codeName
                                ].displayLabel = "";
                                form[data.formId].data[fields[i].codeName].id =
                                    "";
                                if (
                                    selectState &&
                                    selectState[fields[i].codeName]
                                )
                                    selectState[fields[i].codeName].value = "";
                                dependencies[j].id = data.id;
                                dependencies[j].value = data.value as string;
                                fields[i].options = [];
                            } else if (
                                dependencies[j].dependsOn === data.codeName
                            ) {
                                dependencies[j].id = data.id;
                            }
                        }
                        if (fields.length) {
                            updatedDependencies = {
                                ...updatedDependencies,
                                [fields[i].codeName]: fields[i],
                            };
                        }
                    }
                    saveToStorage(
                        "local",
                        "dependencies",
                        JSON.stringify(updatedDependencies),
                    );
                }
            }
        } else {
            // (1.1) if formId object doesn't exists, spread all forms and add the new formId object and add FormData object inside it
            form = {
                ...form,
                [data.formId]: {
                    visited: true,
                    data: { [data.codeName]: data },
                },
            };
            if (data?.type === "select") {
                let fields = [...Object.values(dependenciesWithoutDuplicates)];
                fields = fields.map((obj) => {
                    obj.dependency = obj.dependency.filter(
                        (dep, index, self) => {
                            return (
                                index ===
                                self.findIndex(
                                    (d) => d.dependsOn === dep.dependsOn,
                                )
                            );
                        },
                    );
                    return obj;
                });
                let updatedDependencies = {};
                for (let i = 0; i < fields.length; i++) {
                    const dependencies = [...fields[i].dependency];
                    for (let j = 0; j < dependencies.length; j++) {
                        if (dependencies[j].dependsOn === data.codeName) {
                            dependencies[j].id = data.id;
                            dependencies[j].value = data.value as string;
                        }
                    }
                    if (fields.length) {
                        updatedDependencies = {
                            ...updatedDependencies,
                            [fields[i].codeName]: fields[i],
                        };
                    }
                }
                saveToStorage(
                    "local",
                    "dependencies",
                    JSON.stringify(updatedDependencies),
                );
            }
        }
    }
    saveSharedFieldsInsideLocalStorage(data);
    saveToStorage(STORAGE_TYPE, "form", JSON.stringify(form));
    return form;
};

export function getInitialForm(): AllForms {
    // initialize form from storage if exits
    const loadedFormFromStorage = getFromStorage(STORAGE_TYPE, "form");
    if (loadedFormFromStorage && loadedFormFromStorage.length)
        return JSON.parse(loadedFormFromStorage) as AllForms;
    return {};
}

export function getInitialDependencies(): AllFieldsDependency {
    // initialize data dependencies from storage if exits
    const loadedDependenciesFromStorage = getFromStorage(
        STORAGE_TYPE,
        "dependencies",
    );
    if (loadedDependenciesFromStorage && loadedDependenciesFromStorage.length)
        return JSON.parse(loadedDependenciesFromStorage) as AllFieldsDependency;
    return {};
}

// this method gets fired when thank you message is loaded on the screen
export function removeFormById(forms: AllForms, formId: number): AllForms {
    if (forms && formId) {
        if (forms[formId]) {
            delete forms[formId];
        }
    }
    saveToStorage(STORAGE_TYPE, "form", JSON.stringify(forms));
    return forms;
}

export function removeError(
    formErrors: FormErrors,
    payload: string,
): FormErrors {
    delete formErrors[payload];
    return formErrors;
}

export function fieldDependencyHandler(
    allFieldsDependency: AllFieldsDependency | undefined,
    field: FieldDependencyPayload,
): AllFieldsDependency {
    allFieldsDependency = removeDuplicates(
        allFieldsDependency as AllFieldsDependency,
    );
    if (allFieldsDependency?.[field.codeName]?.dependency) {
        //check if dependency hasn't been added before
        const idx = allFieldsDependency?.[field.codeName]?.dependency.findIndex(
            (item) => {
                item.dependsOn === field.dependency.dependsOn;
            },
        );
        if (idx === -1)
            allFieldsDependency?.[field.codeName]?.dependency.push(
                field.dependency,
            );
    } else {
        allFieldsDependency = {
            ...allFieldsDependency,
            [field.codeName]: {
                codeName: field.codeName,
                isLoading: field?.isLoading ?? false,
                options: [],
                dependency: [field.dependency],
                formId: field.formId,
            },
        };
    }
    // saveToStorage("local", "dependencies", JSON.stringify(allFieldsDependency));
    return allFieldsDependency;
}

export async function fetchDataHandler(
    allFieldsDependency: AllFieldsDependency,
    formJson: DomainForm | undefined,
    payload: GenericField,
): Promise<AllFieldsDependency> {
    // if (allFieldsDependency) {
    allFieldsDependency = removeDuplicates(allFieldsDependency);
    let fields = Object.values(allFieldsDependency);
    let updatedDependencies = {};
    if (fields.length === 0) {
        fields = Object.values(
            JSON.parse(
                getFromStorage("local", "dependencies").length
                    ? getFromStorage("local", "dependencies")
                    : "[]",
            ) as AllFieldsDependency,
        );
    }
    fields = fields.map((obj) => {
        obj.dependency = obj.dependency.filter((dep, index, self) => {
            return (
                index === self.findIndex((d) => d.dependsOn === dep.dependsOn)
            );
        });
        return obj;
    });
    for (let i = 0; i < fields.length; i++) {
        const dependencies = fields[i].dependency;
        for (let j = 0; j < dependencies.length; j++) {
            if (dependencies[j].dependsOn === payload.codeName) {
                fields[i].options = [];
                // check if one of the dependencies doesn't have a value
                const idx = dependencies.findIndex(
                    (item) => item.value === "" || item.value === "-1",
                );
                // if all dependencies have value -> call getDataDependency
                if (idx === -1) {
                    const res = await getDataDependency(
                        fields[i],
                        formJson as DomainForm,
                        fields[i].codeName,
                    );
                    fields[i].options = res;
                }
            }
        }
        if (fields.length) {
            updatedDependencies = {
                ...updatedDependencies,
                [fields[i].codeName]: fields[i],
            };
        }
    }
    // }
    saveToStorage(
        "local",
        "dependencies",
        JSON.stringify(
            Object.values(allFieldsDependency).length
                ? allFieldsDependency
                : updatedDependencies,
        ),
    );
    return Object.values(allFieldsDependency).length
        ? allFieldsDependency
        : updatedDependencies;
}

export function toggleSelectLoadingHandler(
    selectState: SelectState,
    allFieldsDependency: AllFieldsDependency,
    payload: GenericField,
): SelectState {
    allFieldsDependency = removeDuplicates(allFieldsDependency);
    let fields = [...Object.values(allFieldsDependency)];
    if (fields.length === 0) {
        fields = Object.values(
            JSON.parse(
                getFromStorage("local", "dependencies").length
                    ? getFromStorage("local", "dependencies")
                    : "[]",
            ) as AllFieldsDependency,
        );
    }
    for (let i = 0; i < fields.length; i++) {
        const dependencies = fields[i].dependency;
        if (fields[i].codeName === payload.codeName) {
            //@ts-ignore
            selectState = {
                ...selectState,
                [payload.codeName]: {
                    ...[payload.codeName],
                    value: payload.value as string,
                    displayLabel: payload.displayLabel as string,
                },
            };
        }
        for (let j = 0; j < dependencies.length; j++) {
            if (dependencies[j].dependsOn === payload.codeName) {
                dependencies[j].value = payload.value as string;
                const idx = dependencies.findIndex(
                    (item) => item.value === "" || item.value === "-1",
                );
                if (idx === -1) {
                    //@ts-ignore
                    selectState = {
                        ...selectState,
                        [fields[i].codeName]: {
                            ...[fields[i].codeName],
                            value: "",
                            displayLabel: "" as string,
                            loading: payload.loading as boolean,
                        },
                    };
                } else {
                    //@ts-ignore
                    selectState = {
                        ...selectState,
                        [fields[i].codeName]: {
                            ...[fields[i].codeName],
                            value: "-1",
                            displayLabel: `Select ${fields[i].codeName
                                .split(/(?=[A-Z])/)
                                .join(" ")}`,
                            loading: payload.loading as boolean,
                        },
                    };
                }
            }
        }
    }
    return selectState;
}

export function initSelectLoadingHandler(
    selectLoading: SelectState,
    payload: GenericField,
): SelectState {
    selectLoading = {
        ...selectLoading,
        [payload.codeName]: {
            codeName: payload.codeName,
            loading: payload.loading as boolean,
            displayLabel: getDisplayLabel(
                payload.codeName,
                payload.formId as number,
            ),
            value: getValue(payload.codeName, payload.formId as number),
        },
    };
    return selectLoading;
}

function getDisplayLabel(codeName: string, formId: number): string {
    const loadedData = getFromStorage("local", "form");
    if (loadedData && loadedData !== "") {
        const parsedLoadedData: AllForms = JSON.parse(loadedData) as AllForms;
        return parsedLoadedData?.[formId]?.data?.[codeName]
            ?.displayLabel as string;
    }
    return "";
}

function getValue(codeName: string, formId: number): string {
    const loadedData = getFromStorage("local", "form");
    if (loadedData && loadedData !== "") {
        const parsedLoadedData: AllForms = JSON.parse(loadedData) as AllForms;
        return parsedLoadedData?.[formId]?.data?.[codeName]?.value as string;
    }
    return "";
}

function checkConditionalFields(
    allConditionalLogicFields: AllConditionalLogicFieldsByForm,
    changedField: IField,
    set: Setter,
): { show: Boolean; codeName: string } {
    let returnedValue: { show: Boolean; codeName: string } = {
        show: true,
        codeName: "",
    };
    const conditionOperators = {
        equal: (value1: string, value2: string) => value1 === value2,
        notEqual: (value1: string, value2: string) => value1 !== value2,
    };
    if (
        allConditionalLogicFields &&
        allConditionalLogicFields[changedField.formId]
    ) {
        const keys = Object.keys(
            allConditionalLogicFields[changedField.formId],
        );
        keys.forEach((key) => {
            if (
                allConditionalLogicFields[changedField.formId]?.[key] &&
                allConditionalLogicFields[changedField.formId][key]?.isActive
            ) {
                const conditions =
                    allConditionalLogicFields[changedField.formId][key]
                        .conditions;
                for (let i = 0; i < conditions.length; i++) {
                    if (conditions[i].codeName === changedField.codeName) {
                        let finalState: boolean =
                            allConditionalLogicFields[changedField.formId]?.[
                                key
                            ]?.operator === "AND"
                                ? true
                                : false;
                        const state = conditionOperators[
                            conditions[i].operator
                        ](
                            changedField.value.toString().trim(),
                            conditions[i].value.trim(),
                        );

                        finalState =
                            allConditionalLogicFields[changedField.formId]?.[
                                key
                            ].operator === "AND"
                                ? finalState && state
                                : finalState || state;

                        const show =
                            allConditionalLogicFields[changedField.formId]?.[
                                key
                            ]?.action === "show"
                                ? finalState
                                : !finalState;
                        allConditionalLogicFields[changedField.formId][
                            key
                        ].show = show;
                        returnedValue = { show: show, codeName: key };
                    }
                }
            } else {
                allConditionalLogicFields[changedField.formId][key].show = true;
                returnedValue = { show: true, codeName: key };
            }
        });
    }
    set(AllConditionalLogicFieldsAtom, allConditionalLogicFields);
    return returnedValue;
}

export interface SharedFields {
    [codeName: string]: string;
}

function saveSharedFieldsInsideLocalStorage(field: IField) {
    if (SHARED_FIELDS_CODENAME.includes(field.codeName)) {
        const loadedData = getFromStorage("local", "sharedFields");
        let tempObj: SharedFields = {};
        if (loadedData && loadedData.length) {
            const parsedLoadedData: SharedFields = JSON.parse(
                loadedData,
            ) as SharedFields;
            tempObj = {
                ...parsedLoadedData,
                [field.codeName]: field.value as string,
            };
        } else {
            tempObj = {
                [field.codeName]: field.value as string,
            };
        }
        saveToStorage("local", "sharedFields", JSON.stringify(tempObj));
    }
}

function removeDuplicates(
    dependencies: AllFieldsDependency,
): AllFieldsDependency {
    for (const key in dependencies) {
        dependencies[key].dependency = dependencies[key].dependency.filter(
            (item, index, self) =>
                index === self.findIndex((t) => t.dependsOn === item.dependsOn),
        );
    }
    return dependencies;
}
