import { ArgsType, TranslationsObject } from "@genericTypes/sharedTypes";
import { GQLDomainQuery, GQLTranslations } from "@gql/types/gql";
import { titleDecoder } from "@lib/sharedUtils";

type translationsKey = {
    key: string;
    value: string;
};
type translationsKeys = translationsKey[];
export function formatTranslation(domain: GQLDomainQuery): TranslationsObject {
    // Extract keys starting with "Translation_"
    const translationKeys = Object.keys(domain).filter((key) =>
        key.startsWith("Translation_"),
    );

    // Merge array values of translation keys into one key-value object
    const mergedTranslations = translationKeys.reduce(
        (result: { [x: string]: string }, key) => {
            // @ts-ignore
            const translationsArray: translationsKeys = domain[
                key
            ] as translationsKeys;
            translationsArray.forEach(({ key: translationKey, value }) => {
                result[translationKey] = value;
            });
            return result;
        },
        {},
    );
    return mergedTranslations as TranslationsObject;
}

export function translate(
    allKeys: TranslationsObject | undefined,
    key: string,
    args?: ArgsType,
): string {
    return allKeys?.[key] ? titleDecoder(allKeys?.[key], args) : key;
}

export const formatTranslations = (
    translations: GQLTranslations[],
): TranslationsObject => {
    const translationsObject: TranslationsObject = {};
    translations.forEach((item) => {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        translationsObject[item.key] = item.value;
    });
    return translationsObject;
};
