import { atom } from "jotai";
// import { Offer } from "@components/category/bestOverAll/listItem";
import { GQLOffers } from "@gql/types/gql";
import { QueryClient } from "@tanstack/react-query";
import { DEFAULT_OPTIONS } from "@lib/reactQueryDefaultOptions";
export const comparisonVendors = atom<GQLOffers[]>([]);
export const comparisonVendorsOnHold = atom([]);
export const showModal = atom(false);

const queryClientOfferLinks = new QueryClient(DEFAULT_OPTIONS);

export const queryClientOffersLinks = atom(queryClientOfferLinks);
