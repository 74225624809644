import { FieldDependency } from "@genericTypes/form-store-types";
import { Field, FieldDataDependency } from "@genericTypes/sharedTypes";
import { DomainForm } from "pages/api/domainForm";
import { getLookupsAndVariations } from "src/api/lookupsAndVariations";

export const getDataDependency = async (
    mappedField: FieldDependency,
    formJson: DomainForm,
    fieldCodeName: string,
) => {
    let currentField: Field | null = null;
    for (let i = 0; i < formJson?.steps.length; i++) {
        const steps = formJson?.steps[i];
        for (let j = 0; j < steps.fields.length; j++) {
            const field = steps.fields[j];
            if (field.origin.codeName === fieldCodeName) currentField = field;
        }
    }
    if (
        currentField &&
        ((currentField.dataDependency &&
            currentField?.dataDependency?.dependency) ||
            (currentField.origin.dataDependency &&
                currentField.origin.dataDependency.dependency))
    ) {
        const { ordering, type, source } =
            currentField.dataDependency ||
            (currentField.origin.dataDependency as FieldDataDependency);
        const variations = mappedField.dependency
            .filter((item) => item.type === "variations")
            .map((item) => item.id)
            .join("");
        const lookups = mappedField.dependency
            .filter((i) => i.type === "lookups")
            .map((item) => item.id)
            .join(",");
        if (lookups || variations) {
            const { data, error } = await getLookupsAndVariations({
                source,
                queries: {
                    orderBy: ordering?.field,
                    orderByDirection: ordering?.direction,
                    type,
                    lookupsId: lookups ? parseInt(lookups) : undefined,
                    variationsIds: variations.length ? variations : undefined,
                },
            });
            if (data && !error) return data;
        }

        return [];
    }
};
