/* eslint-disable @next/next/no-document-import-in-page */
/* eslint-disable @next/next/no-title-in-document-head */
import React, { ReactNode, useEffect, useState } from "react";
import Header from "@components/shared/header";
import Footer from "@components/shared/footer";
import { useQuery } from "@tanstack/react-query";
import Head from "next/head";
import Script from "next/script";
import MinFraudScript from "@components/scripts/minFraudScript";
import LeadId from "@components/scripts/leadid";
import GtmScript from "@components/scripts/gtmScript";
import TwitterTagScript from "@components/scripts/twitterTagScript";
import ClickyScript from "@components/scripts/clickyScript";
import { GQLOffers } from "@gql/types/gql";
import { useAtom } from "jotai";
import { showExitModalOnceAtom, showOnExitModalAtom } from "@store/formStore";
import { showSearchModal } from "@store/generalStore";
import { useRouter } from "next/router";
import Portal from "src/portals/Portal";
import OffersModal from "@components/shared/modal/offersModal";
import ListItem from "@components/category/bestOverAll/listItem";
import {
    ICategoryPageSharedProps,
    ICustomDomain,
    VisitorInfo,
} from "@genericTypes/sharedTypes";
import { getVisitorInfo } from "@lib/sharedUtils";
import FavIcons from "@components/shared/favIcon";
import ScrollToTop from "@components/scrollToTop/ScrollToTop";
import HotJar from "@components/scripts/hotjarScript";
import SearchModal from "@components/shared/modal/searchModal";
import SearchAutoComplete from "@components/shared/searchAutoComplete/searchModal";
import { Mulish, Almarai } from "next/font/google";
import { useFormState } from "@components/shared/form/formReducer/FormReducer";
import InspectorScript from "@components/scripts/inspectorScript";
import { checkVersion } from "@helpers/checkVersion";

const mulish = Mulish({
    weight: ["200", "400", "800"],
    subsets: ["latin"],
});

const almarai = Almarai({
    weight: ["400", "700"],
    subsets: ["arabic"],
});

export type OffersWithPlacements = GQLOffers & {
    placements: { displayOnExitWindow: boolean; showOnThankYou: boolean };
};

interface OfferWithPlacements extends Omit<GQLOffers, "domains"> {
    domains: {
        domainOffers: { placements: { displayOnExitWindow: boolean } }[];
    }[];
}
export default function DefaultLayout({
    children,
    pageType,
    pageProps,
}: {
    children: ReactNode;
    pageType: string;
    pageProps?: any;
}) {
    const router = useRouter();
    const { data: domainInfo } = useQuery<ICustomDomain>({
        queryKey: ["domain"],
    });

    const { data: offersFromVendor } = useQuery<GQLOffers>([
        "offerById",
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        pageProps?.vendorId?.toString(),
    ]);
    const { data: props } = useQuery<ICategoryPageSharedProps>([
        "categoryPageSharedProps",
    ]);
    const { existModalOffers } = props ?? {};

    const [showExitModal, setShowExitModal] = useAtom(showOnExitModalAtom);
    const [showExitModalOnce, setShowExitModalOnce] = useAtom(
        showExitModalOnceAtom,
    );
    const [searchModalVisible, setSearchModalVisible] =
        useAtom(showSearchModal);
    const [exitModalOffers, setExitModalOffers] = useState<GQLOffers[]>([]);
    const visitorInfo: VisitorInfo = getVisitorInfo();
    const [formState] = useFormState();
    const { modalVisibility: showFormModal } = formState;

    useEffect(() => {
        checkVersion();
        setShowExitModal(false);
        setShowExitModalOnce(true);
    }, [router.asPath]);

    const {
        enableJornayaId,
        enableTrustedForm,
        gtmId,
        description,
        clickyCodeId,
        twitterPixel,
        isInspectorEnabled,
    } = domainInfo?.domain || {
        enableJornayaId: false,
        enableTrustedForm: false,
        gtmId: 0,
        description: "",
        isInspectorEnabled: false,
        niceName: "",
        title: "",
        metatags: [],
    };

    const filterOffers = (
        currentOffers: OffersWithPlacements[] | GQLOffers[],
        type: "category" | "vendor",
    ) => {
        if (currentOffers && currentOffers.length && currentOffers.length > 0) {
            if (type === "category") {
                setExitModalOffers(existModalOffers as GQLOffers[]);
            } else {
                const filteredOnExitOffers = (
                    currentOffers as OfferWithPlacements[]
                ).filter(
                    (offer) =>
                        offer.domains[0]?.domainOffers[0].placements
                            .displayOnExitWindow,
                );

                setExitModalOffers(filteredOnExitOffers);
            }
        }
    };

    useEffect(() => {
        if (showExitModal && !showFormModal) {
            if (pageType === "category") {
                filterOffers(
                    existModalOffers?.length ? existModalOffers : [],
                    "category",
                );
                setShowExitModal(true);
            } else if (pageType === "vendor") {
                filterOffers(
                    [offersFromVendor] as OfferWithPlacements[],
                    "vendor",
                );
                setShowExitModal(true);
            } else {
                setExitModalOffers([]);
            }
        }
    }, [showExitModal]);

    const ExistModal = () => {
        return showExitModal &&
            showExitModalOnce &&
            !showFormModal &&
            exitModalOffers.length > 0 ? (
            <Portal>
                <OffersModal
                    showOuterClose={false}
                    onModalClose={() => {
                        setShowExitModal(false);
                        setShowExitModalOnce(false);
                    }}
                >
                    {exitModalOffers?.map((offer, index) => {
                        return (
                            <ListItem
                                key={index}
                                vendor={offer}
                                containerStyle={"bg-[white]"}
                                withCompare={false}
                                categoryId={
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                    (pageProps?.categoryId as number) ?? 0
                                }
                            />
                        );
                    })}
                </OffersModal>
            </Portal>
        ) : (
            <></>
        );
    };

    const SearchModalPortal = () => {
        return searchModalVisible ? (
            <Portal>
                <SearchModal
                    showOuterClose={false}
                    onModalClose={() => {
                        setSearchModalVisible(false);
                    }}
                >
                    <SearchAutoComplete />
                </SearchModal>
            </Portal>
        ) : (
            <></>
        );
    };

    const event = () => {
        if (
            !showFormModal &&
            !searchModalVisible &&
            process.env.NODE_ENV !== "development"
        )
            setShowExitModal(true);
    };
    useEffect(() => {
        document.documentElement.addEventListener("mouseleave", event);

        return () => {
            setExitModalOffers([]);
            document.documentElement.removeEventListener("mouseleave", event);
        };
    }, [showFormModal, searchModalVisible]);

    return (
        <div
            className={
                process.env.NEXT_PUBLIC_DEFAULT_LANG !== "ar"
                    ? mulish.className
                    : almarai.className
            }
        >
            <ExistModal />
            <SearchModalPortal />
            <Head>
                {enableTrustedForm && !visitorInfo.isBot ? (
                    <>
                        <Script
                            id="trustedForms"
                            src={`https://api.trustedform.com/trustedform.js?field=TrustedFormCertUrl&provide_referrer=true${
                                process.env.TRUSTED_FORM !== "capture"
                                    ? "&sandbox=true"
                                    : ""
                            }&load_async=true&l="${
                                new Date().getTime() + Math.random()
                            }"`}
                            strategy="afterInteractive"
                        />
                        <noscript>
                            <img
                                src="http://api.trustedform.com/ns.gif"
                                alt="trusted form"
                            />
                        </noscript>
                    </>
                ) : null}

                {!visitorInfo.isBot && <MinFraudScript />}
                {enableJornayaId && !visitorInfo.isBot ? <LeadId /> : null}
                {gtmId &&
                    !visitorInfo.isBot &&
                    process.env.NODE_ENV !== "development" && (
                        <GtmScript gtmId={gtmId as string} />
                    )}
                {description && (
                    <meta name="description" content={description} />
                )}
                {twitterPixel && !visitorInfo.isBot && (
                    <TwitterTagScript twitterPixel={twitterPixel} />
                )}
                <FavIcons />
            </Head>
            {isInspectorEnabled && !visitorInfo.isBot && (
                <InspectorScript startRecording={showFormModal} />
            )}

            {clickyCodeId && !visitorInfo.isBot && (
                <ClickyScript id={clickyCodeId} />
            )}
            {process.env.NODE_ENV === "production" && !visitorInfo.isBot && (
                <HotJar />
            )}
            <Head>
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: `
                        {
                            "@context": "https://schema.org",
                            "@type": "WebSite",
                            "url": "https://www.several.com/",
                            "potentialAction": {
                              "@type": "SearchAction",
                              "target": {
                                "@type": "EntryPoint",
                                "urlTemplate": "https://several.com/search?q={search_term_string}"
                              },
                              "query-input": "required name=search_term_string"
                            }
                          }
                        `,
                    }}
                />
            </Head>
            <ScrollToTop />
            <Header />
            <main>{children}</main>
            <Footer />
        </div>
    );
}
