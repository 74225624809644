import {
    AllFieldsDependency,
    FieldDependencyPayload,
    AllForms,
    FormErrors,
    GenericField,
    IField,
    SelectState,
    AllConditionalLogicFieldsByForm,
    SelectedCategory,
} from "@genericTypes/form-store-types";
import { AllExpandableFieldsMeta } from "@genericTypes/sharedTypes";
import {
    addUpdateFieldForm,
    fieldDependencyHandler,
    fetchDataHandler,
    getInitialForm,
    initSelectLoadingHandler,
    removeError,
    removeFormById,
    toggleSelectLoadingHandler,
    getInitialDependencies,
} from "@helpers/jotaiHelpers/form-store-helpers";
import { atom } from "jotai";
import { DomainForm } from "pages/api/domainForm";

const initialState = {
    codeName: "",
    value: "",
    formId: 0,
    valid: false,
    changes: false,
    loading: false,
    displayLabel: "",
};

///// MAIN ATOMS
export const formsAtom = atom<AllForms>(getInitialForm()); // form atom .. holds all forms within it
export const formIdAtom = atom<number>(0); // form id atom .. holds form id and is used in removeFromAtom
export const formJsonAtom = atom<DomainForm | undefined>(undefined); // after calling the form api this atom will be set to the form api response
export const formErrorsAtom = atom<FormErrors>({});
export const FormFieldsDependencyAtom = atom<AllFieldsDependency>(
    getInitialDependencies(),
);
export const SelectStateAtom = atom<SelectState>({});
export const leadIdAtom = atom<string>("");
export const showErrorsFormAtom = atom<boolean>(false);
export const showOtpAtom = atom<boolean>(false);
export const showOnExitModalAtom = atom<boolean>(false);
export const showExitModalOnceAtom = atom<boolean>(true);
export const numberOfStepsAtom = atom<number>(0);
export const expandableFieldMetaAtom = atom<AllExpandableFieldsMeta>({});
export const otpCodeAtom = atom<string>("");
export const showFormAtom = atom<boolean>(false);
export const AllConditionalLogicFieldsAtom =
    atom<AllConditionalLogicFieldsByForm>({});
export const selectedCategoryAtom = atom<SelectedCategory | null>(null);
export const TCPAMessageAtom = atom("");
export interface MetaField {
    contentType: string;
    fieldType?: string;
    addressComponentType: string;
    ignoreField: boolean;
}

///// DERIVED ATOMS
export const addFieldAtom = atom(
    // add atom .. adds field to form
    null,
    (get, set, payload: IField = initialState) => {
        set(
            formsAtom,
            addUpdateFieldForm(
                get(formsAtom),
                get(FormFieldsDependencyAtom),
                get(SelectStateAtom),
                get(AllConditionalLogicFieldsAtom),
                set,
                payload,
            ),
        );
    },
);
export const removeFormAtom = atom(null, (get, set, payload: number) => {
    set(formsAtom, removeFormById(get(formsAtom), payload));
});

export const removeErrorByCodeName = atom(null, (get, set, payload: string) => {
    set(formErrorsAtom, removeError(get(formErrorsAtom), payload));
});

export const initFieldsDependency = atom(
    (get) => get(FormFieldsDependencyAtom),
    (get, set, payload: FieldDependencyPayload) => {
        set(
            FormFieldsDependencyAtom,
            fieldDependencyHandler(get(FormFieldsDependencyAtom), payload),
        );
    },
);

export const deleteDataDependencyAtom = atom(null, (get, set) => {
    set(FormFieldsDependencyAtom, {});
});

export const fetchDataHandlerAtom = atom(
    (get) => get(FormFieldsDependencyAtom),
    async (get, set, payload: GenericField) => {
        set(
            FormFieldsDependencyAtom,
            await fetchDataHandler(
                get(FormFieldsDependencyAtom),
                get(formJsonAtom),
                payload,
            ),
        );
    },
);

export const selectLoadingHandler = atom(
    (get) => get(SelectStateAtom),
    (get, set, payload: GenericField) => {
        set(
            SelectStateAtom,
            toggleSelectLoadingHandler(
                get(selectLoadingHandler),
                get(FormFieldsDependencyAtom),
                payload,
            ),
        );
    },
);

export const initSelectLoading = atom(
    null,
    (get, set, payload: GenericField) => {
        set(
            SelectStateAtom,
            initSelectLoadingHandler(get(SelectStateAtom), payload),
        );
    },
);

export const deleteExpandableFieldsAtom = atom(null, (get, set) => {
    set(expandableFieldMetaAtom, {});
});

export const initConditionalFieldsAtom = atom(
    (get) => get(AllConditionalLogicFieldsAtom),
    (get, set, payload: AllConditionalLogicFieldsByForm) => {
        set(AllConditionalLogicFieldsAtom, payload);
    },
);
