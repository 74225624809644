import React, { CSSProperties, ReactElement } from "react";
import styles from "./style.module.scss";

const Spinner = ({
    size,
    className = "",
    color,
    success,
    bgColor = "rgba(0, 0, 0, 0.2)",
}: {
    size: number;
    className?: string;
    color: string;
    success: boolean;
    bgColor?: string;
}): ReactElement => (
    <div
        style={
            {
                borderColor: success ? color : undefined,
                borderLeftColor: color,
                width: size ? `${size}px` : undefined,
                height: size ? `${size}px` : undefined,
                "--bg-color": bgColor,
            } as CSSProperties
        }
        className={`${styles["circle-loader"]} ${
            success ? styles["load-complete"] : ""
        } ${className ?? ""} spinner`}
    >
        <div
            style={{ opacity: success ? 1 : 0, color }}
            className={`${styles["checkmark"]} ${
                success ? styles["draw"] : ""
            }`}
        ></div>
    </div>
);

export default Spinner;
