import {
    FormActionTypes,
    FormInitState,
    FormReducerActions,
} from "@genericTypes/sharedTypes";
import { getVisitorInfo } from "@lib/sharedUtils";
import { useReducer } from "react";
import { createContainer } from "react-tracked";

const initialState: FormInitState = {
    formResponse: null,
    formId: 0,
    buttonLoading: false,
    currentStep: 0,
    allErrorsField: [],
    domainFormId: 0,
    isLoading: false,
    timer: 0,
    originalTimer: 0,
    showOtpError: false,
    thankYouOffers: [],
    showGenericErrorMessage: null,
    visitorInfo: getVisitorInfo(),
    fieldRegulations: null,
    offerClicksLoading: false,
    offerClicks: [],
    listRequestId: "",
    formErrors: null,
    showOtp: false,
    leadId: "",
    showInvalidFieldsFrom: false,
    showThankYou: false,
    expandableField: null,
    apiCallInProgress: false,
    tcpaMessage: "",
    stepToShowBackBtn: 2,
    modalVisibility: false,
    fieldsState: {},
};

const reducer = (
    state: FormInitState,
    action: FormReducerActions,
): FormInitState => {
    switch (action.type) {
        case FormActionTypes.SET_STEP:
            return {
                ...state,
                currentStep: action.payload,
            };
        case FormActionTypes.SET_BUTTON_LOADING:
            return {
                ...state,
                buttonLoading: action.payload,
            };
        case FormActionTypes.SET_FORM_RESPONSE:
            return {
                ...state,
                formResponse: action.payload,
            };
        case FormActionTypes.SET_FORM_ID:
            return {
                ...state,
                formId: action.payload,
            };
        case FormActionTypes.SET_ORIGINAL_TIME:
            return {
                ...state,
                originalTimer: action.payload,
            };
        case FormActionTypes.SET_OTP_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case FormActionTypes.SET_THANK_YOU_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case FormActionTypes.SET_DOMAIN_FORM_ID:
            return {
                ...state,
                domainFormId: action.payload,
            };
        case FormActionTypes.SET_LEAD_ID:
            return {
                ...state,
                leadId: action.payload,
            };
        case FormActionTypes.SET_SHOW_OTP_ERROR:
            return {
                ...state,
                showOtpError: action.payload,
            };
        case FormActionTypes.SET_SHOW_THANK_YOU:
            return {
                ...state,
                showThankYou: action.payload,
            };
        case FormActionTypes.SET_SHOW_INVALID_FIELDS:
            return {
                ...state,
                showInvalidFieldsFrom: action.payload,
            };
        case FormActionTypes.SET_ERRORS_AFTER_SUBMIT:
            return {
                ...state,
                allErrorsField: action.payload,
            };
        case FormActionTypes.SET_ERRORS_AFTER_SUBMIT_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case FormActionTypes.SET_THANK_YOU_OFFERS:
            return {
                ...state,
                thankYouOffers: action.payload,
            };
        case FormActionTypes.SET_GENERIC_ERROR_MESSAGE:
            return {
                ...state,
                showGenericErrorMessage: action.payload,
            };
        case FormActionTypes.SET_OFFERS_CLICK_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case FormActionTypes.SET_REGULATIONS:
            return {
                ...state,
                fieldRegulations: action.payload,
            };
        case FormActionTypes.REMOVE_ALL_ERRORS_FIELDS:
            return {
                ...state,
                allErrorsField: [],
            };
        case FormActionTypes.SET_EXPANDABLE_FIELD:
            return {
                ...state,
                expandableField: action.payload,
            };
        case FormActionTypes.INIT_FORM:
            return {
                ...state,
                ...action.payload,
            };
        case FormActionTypes.SET_ERRORS: {
            return {
                ...state,
                formErrors: action.payload,
            };
        }
        case FormActionTypes.SET_API_CALL_IN_PROGRESS:
            return {
                ...state,
                apiCallInProgress: action.payload,
            };
        case FormActionTypes.SET_TCPA_MESSAGE:
            return {
                ...state,
                tcpaMessage: action.payload,
            };
        case FormActionTypes.SET_STEP_TO_SHOW_BACK_BTN:
            return {
                ...state,
                stepToShowBackBtn: action.payload,
            };
        case FormActionTypes.CLOSE_FORM_MODAL:
            return {
                ...state,
                ...action.payload,
            };
        case FormActionTypes.SET_MODAL_VISIBILITY:
            return {
                ...state,
                modalVisibility: action.payload,
            };
        default:
            return state;
    }
};

const useFormReducer = () => useReducer(reducer, initialState);

export const {
    Provider: FormProvider,
    useTracked: useFormState,
    useUpdate: useDispatch,
} = createContainer(useFormReducer);
