import { useRouter } from "next/router";
import React, { ReactElement, useEffect } from "react";
export default function DMCA(): ReactElement {
    const router = useRouter();
    useEffect(() => {
        const updateDmcaHref = function () {
            const e = "dmca-badge";

            const t = "refurl";
            const n = document.querySelectorAll("a." + e);
            if (n?.[0]?.getAttribute("href")?.indexOf("refurl") || 0 < 0) {
                if (n?.length) {
                    for (let r = 0; r < n.length; r++) {
                        const i = n[r] as HTMLAnchorElement;
                        i.href = `${i?.href}${
                            i?.href?.indexOf("?") === -1 ? "?" : "&"
                        }${t}=${document?.location.href}`;
                    }
                }
            }
        };
        updateDmcaHref();
    }, [router.asPath]);

    return (
        <>
            <a
                href="//www.dmca.com/Protection/Status.aspx?ID=b3010b68-367a-4d60-9e11-5557a1eeddc9"
                title="DMCA.com Protection Status"
                className="dmca-badge"
            >
                <img
                    loading="lazy"
                    src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-09.png?ID=b3010b68-367a-4d60-9e11-5557a1eeddc9"
                    alt="DMCA.com Protection Status"
                />
            </a>
        </>
    );
}
