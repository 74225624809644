// import Spinner from "@components/shared/spinner";
import React, { ReactElement, ReactNode, useEffect } from "react";
import CloseIcon from "../closeIcon";
import styles from "./styles.module.scss";
import { useAtom } from "jotai";
import { showSearchModal } from "@store/generalStore";
import XIcon from "../xIcon";

interface Props {
    className?: string;
    children: ReactNode;
    showOuterClose: boolean;
    onModalClose?: () => void;
}

export default function SearchModal(props: Props): ReactElement {
    const [, setModalVisibility] = useAtom(showSearchModal);
    const { className = "", children, showOuterClose, onModalClose } = props;
    const hideModal = () => {
        setModalVisibility(false);
        if (onModalClose !== undefined) onModalClose();
    };

    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                hideModal();
            }
        };
        window.addEventListener("keydown", handleEsc);
        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, []);

    return (
        <div
            className={`flex items-center justify-end ${styles["modalStyle"]}`}
        >
            {showOuterClose && (
                <span className={styles["closeIcon"]} onClick={hideModal}>
                    <CloseIcon />
                </span>
            )}
            <div
                className={`${styles["contentStyle"]} ${
                    className ?? ""
                } w-[100%] max-w-[430px] sm:max-w-7xl text-left`}
            >
                <div className="mt-4 md:mt-8 p-4">
                    <div className="flex justify-end z-10 relative pb-4">
                        <span
                            className={`cursor-pointer text-white ${styles["hoverStyle"]}`}
                            onClick={hideModal}
                        >
                            <XIcon />
                        </span>
                    </div>
                    <div className="">{children}</div>
                </div>
            </div>
        </div>
    );
}
