import React from "react";

function ScrollIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            fill="none"
            viewBox="0 0 42 42"
            className=""
        >
            <circle
                cx="21"
                cy="21"
                r="19"
                fill="#14244D"
                stroke="#fff"
                strokeWidth={2}
            ></circle>
            <path fill="#fff" d="M27 23l-7-7-7 7h14z"></path>
        </svg>
    );
}

export default ScrollIcon;
