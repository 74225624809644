type StorageType = "local" | "session";
export function saveToStorage(
    storageType: StorageType,
    key: string,
    value: string,
) {
    if (storageType === "local" && key && value) {
        try {
            localStorage?.setItem(key, value);
        } catch (error) {
            return;
        }
    } else if (storageType === "session" && key && value) {
        try {
            sessionStorage?.setItem(key, value);
        } catch (error) {
            return;
        }
    }
}

export function getFromStorage(storageType: StorageType, key: string) {
    try {
        if (storageType === "local" && key) {
            const data = localStorage?.getItem(key);
            if (data && data !== "" && data.length) {
                return data;
            }
        } else if (storageType === "session" && key) {
            const data = sessionStorage?.getItem(key);
            if (data && data !== "" && data.length) {
                return data;
            }
        }
        return "";
    } catch (error) {
        return "";
    }
}
