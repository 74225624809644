"use client";
// import { activePageAtom } from "@store/generalStore";
// import { useAtom } from "jotai";
import React from "react";
import { showSearchModal } from "@store/generalStore";
import { useAtom } from "jotai";
import styles from "./style.module.scss";

export default function SearchButton() {
    const [, setModalVisible] = useAtom(showSearchModal);

    return (
        <button
            className={`font-extrabold lg1048:font-normal text-white hover:no-underline cursor-pointer ${styles.searchButton}`}
            aria-label="search"
            onClick={() => {
                setModalVisible(true);
            }}
        >
            <SearchIcon />
        </button>
    );
}

export function SearchIcon() {
    // const [activePage] = useAtom(activePageAtom);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M10.33 0a7.65 7.65 0 00-5.427 2.241C2.13 5.015 1.93 9.412 4.306 12.42L.264 16.46a.9.9 0 000 1.275.9.9 0 001.277 0l4.04-4.041c3.01 2.372 7.405 2.177 10.179-.596 2.99-2.99 2.986-7.868-.004-10.857A7.65 7.65 0 0010.33.001V0zm.001 1.786a5.852 5.852 0 014.156 1.724 5.868 5.868 0 010 8.314 5.865 5.865 0 01-8.31-.004 5.857 5.857 0 010-8.305 5.867 5.867 0 014.155-1.729z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}
