import React from "react";

function XIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            fill="none"
            viewBox="0 0 17 17"
        >
            <path
                fill="currentColor"
                stroke="currentColor"
                d="M16.28.718l.002.001a.742.742 0 010 1.052h0L9.907 8.147l-.354.354.354.354 6.375 6.375a.744.744 0 010 1.053h0a.744.744 0 01-1.053 0L8.854 9.907 8.5 9.553l-.354.354-6.375 6.375a.744.744 0 01-1.053 0h0a.744.744 0 010-1.053l6.375-6.375.354-.354-.354-.354L.718 1.772s0 0 0 0a.744.744 0 010-1.053h0a.744.744 0 011.053 0l6.375 6.374.354.354.354-.354L15.229.718h0a.743.743 0 011.052 0z"
            ></path>
        </svg>
    );
}

export default XIcon;
