// import Spinner from "@components/shared/spinner";
import React, { ReactElement, ReactNode } from "react";
import CloseIcon from "../closeIcon";
import styles from "./styles.module.scss";
import { useAtom } from "jotai";
import XIcon from "../xIcon";
import { showOnExitModalAtom } from "@store/formStore";

interface Props {
    className?: string;
    children: ReactNode;
    showOuterClose: boolean;
    onModalClose?: () => void;
}

export default function OffersModal(props: Props): ReactElement {
    const [, setModalVisibility] = useAtom(showOnExitModalAtom);
    const { className = "", children, showOuterClose, onModalClose } = props;
    const showModalHandler = () => {
        setModalVisibility(false);
        if (onModalClose !== undefined) onModalClose();
    };

    return (
        <section
            className={`flex items-center justify-end ${styles["modalStyle"]}`}
        >
            {showOuterClose && (
                <span
                    className={styles["closeIcon"]}
                    onClick={showModalHandler}
                >
                    <CloseIcon />
                </span>
            )}
            <div
                className={`${styles["contentStyle"]} ${
                    className ?? ""
                } w-[100%] max-w-[330px] sm:max-w-[800px] overflow-hidden text-left`}
            >
                <div className="mt-4">
                    <div className="flex justify-end z-10 relative pb-4">
                        <span
                            className={`cursor-pointer  ${styles["hoverStyle"]}`}
                            onClick={showModalHandler}
                        >
                            <XIcon />
                        </span>
                    </div>
                    <div className="overflow-y-auto max-h-[93vh]">
                        {children}
                    </div>
                </div>
            </div>
        </section>
    );
}
