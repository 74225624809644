import React from "react";

function FacebookMobile() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="40"
            fill="none"
            viewBox="0 0 44 40"
        >
            <rect width="44" height="40" fill="#F2F2F2" rx="8"></rect>
            <mask
                id="mask0_1_51196"
                style={{ maskType: "alpha" }}
                width="21"
                height="18"
                x="12"
                y="11"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#5780BB" d="M12 11H32.318V28.236H12z"></path>
            </mask>
            <g mask="url(#mask0_1_51196)">
                <path
                    fill="#454545"
                    d="M20.058 28h3.913v-8.578h2.732l.297-2.87h-3.029v-1.631c0-.684.149-.943.9-.943H27V11h-2.724c-2.919 0-4.233 1.12-4.233 3.265v2.295H18v2.902h2.043L20.058 28z"
                ></path>
            </g>
        </svg>
    );
}

export default FacebookMobile;
