export const DEFAULT_OPTIONS = {
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
            queryFn: async () => {
                return new Promise((resolve) => {
                    resolve("");
                });
            },
        },
    },
};
