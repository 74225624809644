import React from "react";

function Discount() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="13"
            fill="none"
            viewBox="0 0 10 13"
        >
            <path
                fill="#E85951"
                fillRule="evenodd"
                d="M.948 5.173c.289 1.232.784 2.135 1.733 2.34C.741 4.64 5.075 2.424 4.704 0c3.673 2.546 2.682 4.722 2.352 6.077-.454 1.724 1.692 2.217 1.32-.534 1.404 1.601 1.404 3.572.579 5.091-1.775 3.162-6.77 3.162-8.42-.041-.825-1.56-.702-3.613.413-5.42z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#E85951"
                fillRule="evenodd"
                d="M1.939 4.804c-1.321-2.217.949-3.08.908-4.27 1.073 2.217-1.032 2.463-.908 4.27z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Discount;
