import React, { useEffect, useState } from "react";
import ScrollIcon from "./scrollIcon";
import styles from "./styles.module.scss";

const ScrollToTop = () => {
    const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);

    const handleScroll = () => {
        const rootElement = document?.documentElement;
        const scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
        if (rootElement.scrollTop / scrollTotal > 0.1) {
            // Show button
            setShowScrollToTopButton(true);
        } else {
            // Hide button
            setShowScrollToTopButton(false);
        }
    };

    useEffect(() => {
        document.addEventListener("scroll", handleScroll);

        return () => {
            document.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div
            className={`${styles.scrollToTopContainer} fixed ${
                showScrollToTopButton ? "" : "hidden"
            } float-end me-5 md:me-20 bottom-[70px] right-[10px] md:right-[20px] md:bottom-[20px] hover:cursor-pointer z-[999]`}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
            <ScrollIcon />
        </div>
    );
};

export default ScrollToTop;
