import React, { ReactElement } from "react";

export default function SeveralLogo(): ReactElement {
    return (
        <svg
            width="175"
            height="45"
            viewBox="0 0 175 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.04478 43.351C4.11373 42.4921 2.50878 41.0369 1.4654 39.199C0.506904 37.5483 0.00138943 35.6737 0 33.7648H7.81547C7.79138 34.4172 7.92935 35.0654 8.21704 35.6514C8.50473 36.2375 8.93317 36.7431 9.46404 37.123C10.5631 37.9778 12.0896 38.4052 14.0434 38.4052C16.1805 38.4052 17.7069 38.1 18.6839 37.4894C19.1629 37.1862 19.5516 36.76 19.8094 36.2551C20.0672 35.7503 20.1846 35.1854 20.1493 34.6196C20.1588 34.2713 20.099 33.9245 19.9732 33.5996C19.8474 33.2746 19.6582 32.9779 19.4166 32.7268C18.7815 32.1505 18.0327 31.7137 17.2185 31.4446C15.982 30.9959 14.7174 30.6287 13.4328 30.3455C11.2968 29.8761 9.21111 29.2013 7.20489 28.3306C5.46725 27.6186 3.92002 26.5105 2.68657 25.0945C1.42685 23.5709 0.774502 21.6356 0.854818 19.6603C0.815851 17.8231 1.32609 16.016 2.32022 14.4704C3.39272 12.9103 4.9003 11.7 6.65536 10.99C8.92801 10.0985 11.3594 9.6829 13.7992 9.76888C16.2833 9.69612 18.756 10.1325 21.0651 11.0511C22.8758 11.7855 24.4124 13.0661 25.4613 14.7146C26.4655 16.4132 26.9731 18.3593 26.9267 20.332H19.1113C19.1126 19.8167 18.998 19.3077 18.776 18.8427C18.5541 18.3777 18.2304 17.9685 17.829 17.6454C16.9742 16.9127 15.5699 16.5464 13.6771 16.5464C10.3189 16.5464 8.67028 17.5843 8.67028 19.6603C8.66251 20.3438 8.92599 21.0025 9.40299 21.4921C10.0909 22.0921 10.9046 22.5303 11.7843 22.7743C13.2055 23.2288 14.6533 23.5959 16.1194 23.8734C19.844 24.6061 22.7748 25.8272 24.8507 27.4758C26.9267 29.1244 28.0258 31.5057 28.0258 34.5586C28.0674 36.5745 27.4452 38.5483 26.2551 40.1759C25.0088 41.8093 23.3103 43.0407 21.3704 43.7173C19.0231 44.5682 16.5398 44.982 14.0434 44.9385C11.2906 45.031 8.55357 44.4878 6.04478 43.351V43.351Z"
                fill="white"
            />
            <path
                d="M57.2068 33.2772H38.4008C38.6187 34.737 39.3043 36.0868 40.3547 37.1238C41.413 38.0139 42.7577 38.4911 44.1403 38.4671C46.5216 38.4671 48.0481 37.6123 48.7197 35.8416H56.8405C56.4252 37.4757 55.6537 38.9978 54.5813 40.2989C53.4015 41.7242 51.9211 42.871 50.2462 43.6571C48.2073 44.5871 45.9775 45.0221 43.7386 44.9266C41.4997 44.8311 39.3151 44.2078 37.3628 43.1076C35.3402 41.9417 33.6891 40.227 32.6003 38.1618C31.4779 36.0681 30.8906 33.7294 30.8906 31.3538C30.8906 28.9783 31.4779 26.6395 32.6003 24.5458C33.7247 22.5067 35.3676 20.8007 37.3628 19.6001C39.4739 18.3694 41.8801 17.7362 44.3235 17.7683C46.7212 17.7295 49.0845 18.3414 51.162 19.539C53.1012 20.7243 54.6836 22.4122 55.7414 24.4237C56.8422 26.4901 57.4089 28.7989 57.39 31.1401C57.3821 31.856 57.3208 32.5704 57.2068 33.2772ZM49.6356 28.1483C49.5725 27.3844 49.2738 26.659 48.7808 26.0723C48.2752 25.4404 47.6264 24.938 46.8879 24.6069C46.1031 24.2447 45.249 24.0572 44.3846 24.0574C43.1668 24.0546 41.976 24.4161 40.9653 25.0953C39.8795 25.8327 39.0983 26.9393 38.7672 28.2093H49.6356V28.1483Z"
                fill="white"
            />
            <path
                d="M111.857 33.2772H93.0508C93.2687 34.737 93.9543 36.0868 95.0047 37.1238C96.063 38.0139 97.4076 38.4911 98.7903 38.4671C101.172 38.4671 102.698 37.6123 103.37 35.8416H111.49C111.075 37.4757 110.304 38.9978 109.231 40.2989C108.052 41.7242 106.571 42.871 104.896 43.6571C102.857 44.5871 100.628 45.0221 98.3886 44.9266C96.1497 44.8311 93.9651 44.2078 92.0128 43.1076C90.0039 41.9243 88.3569 40.214 87.2503 38.1618C86.129 36.0787 85.5416 33.7501 85.5406 31.3844C85.5044 28.9948 86.0937 26.6372 87.2503 24.5458C88.3747 22.5067 90.0176 20.8007 92.0128 19.6001C94.1239 18.3694 96.5301 17.7362 98.9735 17.7683C101.371 17.7295 103.735 18.3414 105.812 19.539C107.751 20.7243 109.334 22.4122 110.391 24.4237C111.492 26.4901 112.059 28.7989 112.04 31.1401C112.032 31.856 111.971 32.5704 111.857 33.2772V33.2772ZM104.286 28.1483C104.222 27.3844 103.924 26.659 103.431 26.0723C102.925 25.4404 102.276 24.938 101.538 24.6069C100.753 24.2447 99.8989 24.0572 99.0345 24.0574C97.8168 24.0546 96.626 24.4161 95.6153 25.0953C94.5294 25.8327 93.7483 26.9393 93.4172 28.2093H104.286V28.1483Z"
                fill="white"
            />
            <path
                d="M123.397 18.6228V22.2252C123.946 21.1022 124.81 20.1634 125.883 19.5236C126.957 18.8838 128.193 18.5708 129.442 18.6228H132.373V25.0949H129.808C128.961 25.0505 128.115 25.1976 127.333 25.5254C126.551 25.8531 125.853 26.3529 125.29 26.9877C124.191 28.27 123.703 30.0407 123.703 32.4219V44.1451H116.07V18.6228H123.397Z"
                fill="white"
            />
            <path
                d="M139.459 43.1069C137.654 41.8463 136.203 40.1433 135.246 38.1612C134.24 36.0324 133.719 33.7074 133.719 31.3532C133.719 28.9989 134.24 26.6739 135.246 24.5451C136.203 22.563 137.654 20.86 139.459 19.5994C141.216 18.3853 143.306 17.7454 145.442 17.7677C147.174 17.7343 148.89 18.1109 150.449 18.8667C151.831 19.5234 152.996 20.5615 153.807 21.8586V18.6225H161.012V44.1449H153.807V40.9088C153.021 42.2044 151.876 43.2438 150.51 43.9006C148.966 44.63 147.272 44.9856 145.564 44.9386C143.39 44.9612 141.261 44.3224 139.459 43.1069V43.1069ZM150.449 37.1232C151.388 36.5484 152.149 35.724 152.647 34.7419C153.188 33.7065 153.461 32.5519 153.441 31.3837C153.469 30.2342 153.195 29.0975 152.647 28.0865C152.132 27.1163 151.375 26.296 150.449 25.7053C149.546 25.1017 148.482 24.7827 147.396 24.7894C146.306 24.7691 145.23 25.0433 144.282 25.5831C143.345 26.1339 142.583 26.9382 142.084 27.9034C141.522 28.975 141.249 30.1744 141.29 31.3837C141.239 32.6402 141.535 33.8865 142.145 34.9861C142.646 35.9341 143.409 36.7183 144.343 37.2453C145.285 37.7376 146.334 37.9893 147.396 37.978C148.473 37.977 149.528 37.6814 150.449 37.1232V37.1232Z"
                fill="white"
            />
            <path
                d="M166.875 9.40332H174.446V44.0845H166.875V9.40332Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M58.7344 23.2632H69.1143L72.1062 32.6662L83.3409 0H93.7819L76.6245 45H67.5878L58.7344 23.2632Z"
                fill="#F9BB21"
            />
        </svg>
    );
}
