import React from "react";

function TicIcon({ color }: { color: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="11"
            fill="none"
            viewBox="0 0 12 11"
        >
            <path
                fill={color}
                d="M.373 7.115a1.168 1.168 0 010-1.628 1.11 1.11 0 011.594 0L4.46 8.035l5.47-6.908a1.109 1.109 0 011.581-.175c.484.397.561 1.12.172 1.614l-6.21 7.842c-.038.055-.081.108-.13.157a1.11 1.11 0 01-1.593 0L.373 7.115z"
            ></path>
        </svg>
    );
}

export default TicIcon;
