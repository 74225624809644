import React, { ReactElement } from "react";
import styles from "./style.module.scss";

export default function Burger({
    isOpened,
    setOpened,
}: {
    isOpened: boolean;
    setOpened: (x: boolean) => void;
}): ReactElement {
    return (
        <div
            className={`${styles.burger} ${
                isOpened ? `${styles.open} fixed` : ""
            }`}
            onClick={() => setOpened(!isOpened)}
        >
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
}
