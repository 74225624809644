"use client";
import React, { ReactElement } from "react";
import Image from "next/legacy/image";
import styles from "./style.module.scss";
import Link from "@components/shared/linkNoPrefetch";
import DMCA from "./dmca";
import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { translate } from "@helpers/translationObjectFormatter";
import { ICustomDomain, TranslationsObject } from "@genericTypes/sharedTypes";

const socialLinks = [
    {
        name: "facebook",
        url: "https://www.facebook.com/Severalcom-102067268535096",
    },
    {
        name: "twitter",
        url: "https://twitter.com/several_com",
    },
    {
        name: "instagram",
        url: "https://www.instagram.com/severaldotcom",
    },
    {
        name: "pinterest",
        url: "https://www.pinterest.com/severaldotcom",
    },
];

export default function Footer(): ReactElement {
    const { data: footerLinks } = useQuery<ICustomDomain, Error>({
        queryKey: ["domain"],
    });
    const staticPagesLinks = footerLinks?.domain?.menus?.find(
        (el) => el?.slug === "several-footer-col-1",
    )?.links;
    const categoriesLinks = footerLinks?.domain?.menus?.find(
        (el) => el?.slug === "several-footer-col-2",
    )?.links;
    const miscellaneousLinks = footerLinks?.domain?.menus?.find(
        (el) => el?.slug === "several-footer-col-3",
    )?.links;

    const { asPath } = useRouter();
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    return (
        <footer className={`${styles.footer} py-10 lg1048:py-20`}>
            <div className="block mb-6  px-5 lg1048:hidden">
                <Image
                    src={"/logo-dark.svg"}
                    alt="Several"
                    width={196}
                    height={25}
                />
            </div>
            <div className="flex  justify-between items-start flex-wrap flex-col-reverse lg1048:flex-row max-w-7xl mx-auto px-5">
                <div>
                    <div className="mb-4 pt-2.5 hidden lg1048:block">
                        <Image
                            src={"/logo-dark.svg"}
                            alt="Several"
                            width={196}
                            height={25}
                        />
                    </div>
                    <div className="flex items-center justify-center mb-4">
                        <span className="text-sm mr-4 rtl:mr-0 rtl:ml-4 ">
                            {` © ${new Date().getFullYear()} Several.com. ${translate(
                                translations,
                                "several_homePage_footer_allRights",
                            )}`}
                        </span>
                        <DMCA key={asPath} />
                    </div>
                    <div className="flex gap-2">
                        {socialLinks?.map((lnk) => (
                            <Link
                                href={lnk.url}
                                key={lnk.url}
                                className={`${styles.social} px-2 py-1 rounded-[8px] items-center justify-center`}
                                target="_blank"
                            >
                                <Image
                                    alt={lnk.name}
                                    width={16}
                                    height={16}
                                    src={`/social-icons/${lnk.name}.svg`}
                                />
                            </Link>
                        ))}
                    </div>
                </div>
                <div
                    className={`flex flex-wrap justify-start lg1048:justify-end lg1048:flex-nowrap  ${styles.ftrLnkWrapper}`}
                >
                    <div className="basis-2/4 lg1048:basis-56 mb-3 ">
                        <ul>
                            {staticPagesLinks?.map((lnk) => (
                                <li key={lnk?.id} className="pb-1">
                                    <Link
                                        href={lnk?.link as string}
                                        className={` ${
                                            lnk?.parentId
                                                ? ""
                                                : `font-bold inline-block mb-1 ${styles.ftrTitle}`
                                        } ${styles.ftrLnk}`}
                                    >
                                        {lnk?.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="basis-2/4 lg1048:basis-56 mb-3  lg:w-[300px] ">
                        {process.env.NEXT_PUBLIC_DEFAULT_LANG === "en" ? (
                            <ul>
                                {categoriesLinks?.map((lnk) => (
                                    <li key={lnk?.id} className="pb-1">
                                        <Link
                                            href={lnk?.link as string}
                                            className={`   ${
                                                lnk?.parentId
                                                    ? ""
                                                    : `font-bold inline-block mb-1 ${styles.ftrTitle}`
                                            } ${styles.ftrLnk}`}
                                        >
                                            {lnk?.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="basis-2/4 lg1048:basis-56 mb-5 ">
                        <ul>
                            {miscellaneousLinks?.map((lnk) => (
                                <li key={lnk?.id} className="pb-1">
                                    <Link
                                        href={lnk?.link as string}
                                        className={` ${
                                            lnk?.parentId
                                                ? ""
                                                : `font-bold inline-block mb-1 ${styles.ftrTitle}`
                                        } ${styles.ftrLnk}`}
                                    >
                                        {lnk?.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}
