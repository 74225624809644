import React from "react";

function CloseIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="17"
            fill="none"
            viewBox="0 0 18 17"
        >
            <path
                fill="#1693F0"
                d="M1.575 1.075c-.889.89-.922 2.297-.073 3.146l4.352 4.352-4.56 4.56c-.888.888-.921 2.297-.073 3.145.849.848 2.257.816 3.146-.073l4.56-4.56 4.352 4.353c.848.848 2.256.816 3.145-.073.889-.89.922-2.298.073-3.146l-4.352-4.352 4.56-4.56c.889-.889.921-2.297.073-3.145-.849-.849-2.257-.816-3.146.073l-4.56 4.56-4.351-4.353c-.849-.848-2.257-.816-3.146.073z"
            ></path>
        </svg>
    );
}

export default CloseIcon;
