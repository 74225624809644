import styles from "./style.module.scss";
import TicIcon from "../icons/TicIcon";
import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { GQLOffers } from "@gql/types/gql";
import { comparisonVendors } from "@store/categoryStore";
import OfferLinks from "@components/shared/offerLinks";
import { PLACE_HOLDER_IMAGE_PATH } from "@lib/sharedUtils";
import { translate } from "@helpers/translationObjectFormatter";
import { TranslationsObject } from "@genericTypes/sharedTypes";
import Discount from "../icons/Discount";
export interface Offer {
    id: number;
    link: string;
    logo: string;
    prices: string[];
    pros: string[];
    cons: string[];
    comparisonAttributes: {
        label: string;
        values: { label: string; value: boolean }[];
    }[];
    featured: string;
    height?: number;
}

export default function ListItem({
    vendor,
    containerStyle,
    withCompare = true,
    categoryId,
}: {
    vendor: GQLOffers;
    containerStyle?: string;
    withCompare?: boolean;
    categoryId?: number;
}) {
    const discountValue = vendor?.attributes?.find(
        (attr) => attr?.baseAttribute?.name === "discountValue",
    )?.value;
    const sellingPoints = vendor?.features;
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    const [comparisonList, setComparisonList] = useAtom(comparisonVendors);

    const isChecked = !!comparisonList.find((el) => el.slug === vendor.slug);

    const changeCheckBoxHandler = (checked: boolean) => {
        if (checked) {
            setComparisonList([...comparisonList, vendor]);
        } else {
            setComparisonList((myList) =>
                myList.filter((el) => el.slug !== vendor.slug),
            );
        }
    };

    return (
        <>
            <div
                itemScope
                itemType="https://schema.org/Offer"
                className={`${
                    styles["orderStyle"]
                }  rounded-lg flex  px-4 flex-col lg:flex-row  items-center justify-between  mt-5 pt-8 pb-6 ${
                    containerStyle ?? ""
                }  ${styles["borderStyle"]} ${
                    isChecked ? styles["selectStyle"] : ""
                } gap-2`}
            >
                <div className={`  lg:w-[21%]  flex justify-center`}>
                    <span
                        itemProp="image"
                        className={`relative block ${styles["topBuyerImage"]}`}
                    >
                        {vendor.logo?.fullPath ? (
                            <img
                                src={vendor.logo?.fullPath}
                                alt="logo"
                                width={150}
                                height={40}
                            />
                        ) : (
                            <>
                                <img
                                    src={PLACE_HOLDER_IMAGE_PATH}
                                    alt="logo"
                                    width={150}
                                    height={40}
                                />
                            </>
                        )}
                    </span>
                </div>
                <div className={`lg:w-[49%] mt-3 lg:mt-0 w-full`}>
                    <h2
                        className="lg:text-lg font-bold lg:leading-7 leading-6 text-start"
                        itemProp="name"
                    >
                        {vendor?.featuresListTitle ?? vendor.title}
                    </h2>
                    <div>
                        {sellingPoints?.map((point) => (
                            <div
                                key={point?.id}
                                className="flex items-baseline gap-2 mt-1"
                            >
                                <div>
                                    <TicIcon color="#1693F0" />
                                </div>
                                <span
                                    className={`${styles["paragraphColor"]} text-sm leading-7  `}
                                >
                                    {point?.description}
                                </span>
                            </div>
                        ))}
                    </div>

                    {withCompare && (
                        <div className={styles["checkBoxStyle"]}>
                            <input
                                id={`checkbox-${vendor.id as number}`}
                                onChange={(e) =>
                                    changeCheckBoxHandler(e.target.checked)
                                }
                                type="checkbox"
                                checked={isChecked}
                                className={`rounded cursor-pointer inline-flex justify-center items-center ${
                                    isChecked ? "bg-black" : "bg-white"
                                }`}
                            />

                            <label
                                htmlFor={`checkbox-${vendor.id as number}`}
                                className=" leading-5 ml-2 mt-3 lg:mt-0 inline-block"
                            >
                                <span
                                    className={`${styles["box"]} !me-3`}
                                    style={{
                                        backgroundColor: isChecked
                                            ? "#000"
                                            : undefined,
                                    }}
                                >
                                    <svg viewBox="0,0,50,50">
                                        <path d="M5 30 L 20 45 L 45 5"></path>
                                    </svg>
                                </span>
                                <span className="-ml-1">
                                    {translate(
                                        translations,
                                        "several_general_compareButton",
                                    )}
                                </span>
                            </label>
                        </div>
                    )}
                </div>

                <div className=" lg:w-[30%] w-full sm:w-[40%] mt-8 lg:mt-0 ">
                    {discountValue && (
                        <span className="flex justify-center items-center font-bold gap-2 mb-4 text-[#E85951]">
                            <Discount />{" "}
                            {translate(
                                translations,
                                "several_general-DiscountWord",
                            )}{" "}
                            {`${discountValue}%`}
                        </span>
                    )}

                    <OfferLinks
                        vendor={vendor}
                        categoryId={categoryId ?? 0}
                        oneLine={true}
                    />
                </div>
            </div>
        </>
    );
}
