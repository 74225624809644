import { getFromStorage } from "@helpers/storageHelper";
import { getAccessToken } from "@services/cookies";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";

interface SessionResponse {
    success: boolean;
    data: Session;
}

export interface Session {
    accessToken: string | null;
    visitDetails: VisitDetails;
}

export interface VisitDetails {
    country?: string;
    countryCode?: string;
    region?: string;
    city?: string;
    ip?: string;
    visitorId?: string;
    userId?: string;
    visitId?: string;
    trafficSourceNetwork?: string | null;
}

export const getUserSession = async (
    url: string,
    referer: string,
): Promise<{
    data: SessionResponse | null;
    error: Error | null;
}> => {
    const accessToken = getAccessToken();

    const config: AxiosRequestConfig = {
        method: "post",
        url: "/api/session",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken as string}`,
            "X-language": process.env.NEXT_PUBLIC_DEFAULT_LANG as string,
        },
        data: {
            url,
            referer,
        },
    };

    const visitorInfo = getFromStorage("session", "visitorInfo");
    if (visitorInfo) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const parsedVisitorInfo = JSON.parse(visitorInfo);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!parsedVisitorInfo?.isBot) {
            const { data: sessionResponse, error } =
                await apiHandler<SessionResponse>(() => HTTP.client(config));
            return {
                data: sessionResponse ?? null,
                error,
            };
        }
    } else {
        const { data: sessionResponse, error } =
            await apiHandler<SessionResponse>(() => HTTP.client(config));
        return {
            data: sessionResponse ?? null,
            error,
        };
    }

    return { data: null, error: null };
};
