import React from "react";

function PinterestMobile() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="40"
            fill="none"
            viewBox="0 0 44 40"
        >
            <rect width="44" height="40" fill="#F2F2F2" rx="8"></rect>
            <mask
                id="mask0_1_51198"
                style={{ maskType: "alpha" }}
                width="21"
                height="17"
                x="12"
                y="11"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D02D2F" d="M12 11H32.04V28H12z"></path>
            </mask>
            <g mask="url(#mask0_1_51198)">
                <path
                    fill="#454545"
                    d="M17.212 20.839a.434.434 0 00.352.003.382.382 0 00.14-.101.321.321 0 00.071-.146c.048-.174.167-.606.223-.787.055-.181.047-.327-.16-.543-.506-.54-.769-1.224-.738-1.922a4.145 4.145 0 01.405-1.832 4.662 4.662 0 011.196-1.544 5.425 5.425 0 011.793-1.006 5.958 5.958 0 012.1-.304c3 0 4.643 1.609 4.643 3.753 0 2.82-1.42 5.201-3.54 5.201a1.934 1.934 0 01-.789-.128 1.715 1.715 0 01-.636-.428 1.44 1.44 0 01-.347-.634 1.331 1.331 0 01.018-.703c.333-1.24.984-2.577.984-3.482a1.156 1.156 0 00-.058-.554 1.28 1.28 0 00-.314-.482 1.503 1.503 0 00-.514-.325 1.674 1.674 0 00-.622-.108c-1.19 0-2.151 1.08-2.151 2.527a3.38 3.38 0 00.357 1.553l-1.437 5.327a9.727 9.727 0 000 3.718.127.127 0 00.037.065.17.17 0 00.155.037.154.154 0 00.07-.04c.879-.957 1.56-2.04 2.016-3.202.135-.439.794-2.695.794-2.695.3.389.706.705 1.182.92.476.214 1.006.319 1.54.305 3.589 0 6.018-2.868 6.018-6.712 0-2.896-2.802-5.57-7.057-5.57-5.303 0-7.938 3.335-7.938 6.113-.072 1.672.65 3.169 2.207 3.726z"
                ></path>
            </g>
        </svg>
    );
}

export default PinterestMobile;
