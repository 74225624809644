import React, { ReactElement, ReactNode } from "react";
// import Link from "@components/shared/linkNoPrefetch"
import Spinner from "@components/shared/spinner";
import styles from "./button.module.scss";
interface Props {
    className?: string;
    innerClassName?: string;
    children: ReactNode;
    icon?: ReactElement;
    target?: React.HTMLAttributeAnchorTarget | undefined;
    href: string;
    style?: React.CSSProperties | undefined;
    loading?: boolean;
    onClick?: () => void;
    disabled?: boolean;
}

export default function LinkButton(props: Props): ReactElement {
    const {
        className = "",
        children,
        icon,
        href,
        target,
        style,
        innerClassName,
        onClick,
        loading = false,
        disabled = false,
    } = props;

    return (
        <a
            href={href}
            target={target}
            style={style}
            onClick={onClick}
            className={`w-full cursor-pointer  h-[50px]  rounded-lg bg-[#1693F0] hover:bg-[#68beff] flex justify-center items-center text-[white] hover:no-underline ${
                className ?? ""
            } ${styles.button} ${
                disabled ? "pointer-events-none opacity-50 " : ""
            }`}
        >
            {!loading ? (
                <span className={innerClassName}>
                    <span>{icon}</span>
                    <span>{children}</span>
                </span>
            ) : (
                <Spinner size={25} color="#fff" success={false} />
            )}
        </a>
    );
}
