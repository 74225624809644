import { OpenSearchResult } from "@genericTypes/sharedTypes";
import styles from "./styles.module.scss";
import { CSSProperties } from "react";
import { titleDecoder } from "@lib/sharedUtils";
import { getVisitorInfo } from "@lib/sharedUtils";
import { VisitorInfo } from "@genericTypes/sharedTypes";
export default function SearchResult({
    item,
    fromModal = true,
    searchQuery,
}: {
    item: OpenSearchResult;
    fromModal?: boolean;
    searchQuery: string;
}) {
    const visitorInfo: VisitorInfo = getVisitorInfo();

    const highlightSearchQuery = (text: string, query: string) => {
        const regex = new RegExp(query, "gi");
        const ex = text.replace(regex, (match) => {
            return match ? `<em>${match}</em>` : "";
        });

        return ex;
    };

    const getItemOverview = () => {
        if (item._source.modelType === "posts") {
            return item._source.raw?.body ?? "";
        } else {
            return item._source.raw?.overview ?? "";
        }
    };

    return (
        <div
            className={`${styles.searchResult}  md:ps-16  relative`}
            style={
                {
                    "--color": fromModal ? "#fff" : "#000",
                    "--border-color": "#eee",
                } as CSSProperties
            }
        >
            <div
                className={`flex items-center gap-5 text-xl font-bold ${
                    item._source.modelType === "comparison" ? "" : "mb-3"
                }`}
            >
                <a href={item._source.url} className={styles.anchor}>
                    <h3
                        className={styles.searchResultTitle}
                        dangerouslySetInnerHTML={{
                            __html: titleDecoder(
                                item.highlight?.["raw.title"]?.[0] ||
                                    highlightSearchQuery(
                                        item._source.raw.title ?? "",
                                        searchQuery.split(" ").pop() ?? "",
                                    ),
                                {
                                    region: visitorInfo?.region ?? "",
                                },
                            ),
                        }}
                    ></h3>
                </a>
            </div>
            <p
                key={item._id}
                className={styles.searchResultOverview}
                dangerouslySetInnerHTML={{
                    __html: getItemOverview(),
                }}
            ></p>
        </div>
    );
}
