import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="17"
            fill="none"
            viewBox="0 0 15 17"
        >
            <path
                fill="#fff"
                d="M8.602 12.306c.307-.273.563-.545.857-.75.217-.154.465-.252.724-.285.259-.033.521 0 .766.097.886.318 1.772.633 2.65.972.846.325 1.185 1.14.838 2.025a5.014 5.014 0 01-1.175 1.801c-.631.607-1.404.814-2.232.833-1.272.027-2.433-.406-3.543-1.024-1.416-.788-2.593-1.9-3.665-3.146C2.485 11.273 1.37 9.556.637 7.583.181 6.356-.073 5.09.02 3.758.144 1.948 1.072.681 2.873.088c.905-.3 1.62.19 1.832 1.173.234 1.07.453 2.142.67 3.214.045.212.039.432-.017.641-.056.209-.16.4-.302.555-.254.28-.536.529-.84.743-.14.105-.173.18-.124.345.35 1.179.96 2.193 1.716 3.113a11.967 11.967 0 002.736 2.403.296.296 0 00.058.031z"
            ></path>
        </svg>
    );
}

export default Icon;
