import React from "react";

function DownArrow({ color }: { color?: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="6"
            fill="none"
            viewBox="0 0 13 6"
        >
            <path
                fill={color ?? `#000`}
                d="M.814.223l5.698 5.555L12.21.223H.814z"
            ></path>
        </svg>
    );
}

export default DownArrow;
