import { Languages } from "@genericTypes/sharedTypes";
export function getUrl(
    language: Languages = process.env.NEXT_PUBLIC_DEFAULT_LANG as Languages,
    pathname: string = "",
): string {
    const urls: { [x: string]: string } = JSON.parse(
        process.env.NEXT_PUBLIC_FRONT_URL as string,
    ) as { [x: string]: string };

    switch (process.env.NEXT_PUBLIC_NODE_ENV) {
        case "development":
            return `${urls[`development-${language}`]}${pathname}`;

        case "production":
            return `${urls[`production-${language}`]}${pathname}`;

        default:
            return `${urls[`staging-${language}`]}${pathname}`;
    }
}
