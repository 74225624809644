import React from "react";

function TwitterMobile() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="43"
            height="40"
            fill="none"
            viewBox="0 0 43 40"
        >
            <rect width="43" height="40" fill="#F2F2F2" rx="8"></rect>
            <path
                fill="#454545"
                d="M12.047 11l7.39 9.88L12 28.914h1.674l6.51-7.034 5.26 7.034h5.696l-7.806-10.436L30.256 11h-1.674l-5.996 6.477L17.742 11h-5.695zm2.46 1.233h2.617L28.678 27.68h-2.616L14.508 12.233z"
            ></path>
        </svg>
    );
}

export default TwitterMobile;
