import { LanguagesData } from "@genericTypes/sharedTypes";
import React, { MouseEventHandler, useEffect, useState } from "react";
import DownArrow from "../customMultiSelect/icons/DownArrow";
import LanguageListItem from "./languageListItem";
import { GQLUrls } from "@gql/types/gql";
import styles from "./style.module.scss";
import AnimateHeight from "../animateHeight";
interface Props {
    options: LanguagesData;
    selectedItem: string;
    onChange: MouseEventHandler<HTMLSpanElement>;
    links: GQLUrls[];
}

const LanguageSwitcher = (props: Props) => {
    const { options, selectedItem } = props;
    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        document.body.addEventListener("click", (e: MouseEvent) => {
            //@ts-ignore
            const { id } = e.target;
            if (!id && showOptions) setShowOptions(false);
        });
    }, [showOptions]);

    return (
        <div
            className={`cursor-pointer  top-[38px] w-[8rem] z-[99] ${styles["languageSwitcherVisible"]}`}
            id="languageSwitcher"
            onClick={() => setShowOptions((prev) => !prev)}
        >
            <div
                className="flex flex-row items-baseline justify-center gap-5"
                id="languageSwitcher"
            >
                <span className="uppercase" id="languageSwitcher">
                    {selectedItem}
                </span>
                <span id="languageSwitcher" className="z-[-99]">
                    <DownArrow color={"#FFF"} />
                </span>
            </div>
            <div
                className={`flex flex-col mt-3 bg-[white] rounded text-center border border-[#0e2450] shadow absolute w-[128px]`}
                id="languageSwitcher"
            >
                <AnimateHeight duration={200} height={showOptions ? "auto" : 0}>
                    {Object.values(options).map((lang) => (
                        <LanguageListItem
                            key={lang.iso}
                            lang={lang.iso}
                            label={lang.label}
                            className={` px-3 py-2 block no-underline ${
                                lang.iso ===
                                process.env.NEXT_PUBLIC_DEFAULT_LANG
                                    ? "bg-[#f9bb21] text-[#4B3A22]"
                                    : "border border-[#f9f9f9] text-[#0e2450] hover:bg-[#f2f2f2]"
                            }`}
                        />
                    ))}
                </AnimateHeight>
            </div>
        </div>
    );
};

export default LanguageSwitcher;
