import React from "react";

function Facebook() {
    return (
        <div className="bg-[#4780c0] rounded hover:bg-[#326fb4] transition">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="69"
                height="35"
                fill="none"
                viewBox="0 0 69 35"
            >
                <path
                    fill="#fff"
                    d="M33.155 24.318h3.04v-6.881h2.12l.232-2.301h-2.353v-1.31c0-.548.116-.756.7-.756h1.653v-2.388h-2.115c-2.268 0-3.289.898-3.289 2.618v1.841h-1.586v2.329h1.586l.012 6.848z"
                ></path>
            </svg>
        </div>
    );
}

export default Facebook;
