import React, { useEffect, useRef, useState } from "react";
import { translate } from "@helpers/translationObjectFormatter";
import { useQuery } from "@tanstack/react-query";
import {
    OpenSearchResult,
    TranslationsObject,
} from "@genericTypes/sharedTypes";
import styles from "./styles.module.scss";
import { getSearchResult } from "src/api/search";
import useDebounce from "@hooks/useDebounce";
import SearchResult from "../searchResult";
import NoResults from "../NoResults";
import Spinner from "@components/shared/spinner";
import { SearchIcon } from "@components/shared/header/SearchButton";

export default function SearchAutoComplete() {
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const debounceSearchQuery = useDebounce(searchQuery, 250);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const { data: searchResult, isFetching } = useQuery<
        OpenSearchResult[],
        Error
    >({
        queryKey: ["searchResults", debounceSearchQuery],
        queryFn: async () => {
            if (!debounceSearchQuery) return [];
            const { data } = await getSearchResult({
                searchQuery: debounceSearchQuery,
            });
            return data ?? [];
        },
        keepPreviousData: true,
    });

    useEffect(() => {
        searchInputRef.current?.focus();
    }, []);

    const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            window.location.href = `/search?q=${debounceSearchQuery}`;
        }
    };

    const getContent = () => {
        if (isFetching) {
            return (
                <div className="flex justify-center items-center w-full h-[200px]">
                    <Spinner
                        bgColor="#ccc"
                        size={50}
                        color="#29b3ff"
                        success={false}
                    />
                </div>
            );
        }
        if (!searchResult?.length && !isFetching && !debounceSearchQuery) {
            return <></>;
        }
        if (!searchResult?.length && !isFetching && debounceSearchQuery) {
            return <NoResults color="#000" />;
        }
        return (
            <>
                <div className={`overflow-y-auto ${styles.searchResults}`}>
                    {!!searchResult?.length && (
                        <div className={` px-4 md:px-0 py-4`}>
                            {searchResult.map((result) => (
                                <SearchResult
                                    fromModal={true}
                                    key={result._id + result._source.modelType}
                                    item={result}
                                    searchQuery={debounceSearchQuery}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </>
        );
    };

    return (
        <div className={`flex-1 w-full relative`}>
            <div className={styles.inputContainer}>
                <SearchIcon />

                <input
                    type="text"
                    className={`${styles.input}  ${
                        isFetching ? styles.loading : ""
                    }  w-full h-[50px] lg:h-[80px]`}
                    placeholder={translate(
                        translations,
                        "several_general_search_placeholder",
                    )}
                    style={{
                        borderRadius: !debounceSearchQuery
                            ? "10px"
                            : "10px 10px 0 0",
                        borderBottom: !debounceSearchQuery
                            ? "none"
                            : "1px solid #dddfe2",
                    }}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    ref={searchInputRef}
                    onKeyDown={handleEnterPress}
                />
            </div>
            <div className="bg-white rounded-b-[10px]">{getContent()}</div>
        </div>
    );
}
