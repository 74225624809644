import React, { ReactElement, useState } from "react";
import Link from "@components/shared/linkNoPrefetch";
import styles from "./style.module.scss";
import { getPhoneNumber, resolveURL } from "@lib/sharedUtils";
import LinkButton from "../form/linkButton";
import { GQLOffers, GQLUrls } from "@gql/types/gql";
import { useQuery } from "@tanstack/react-query";
import { translate } from "@helpers/translationObjectFormatter";
import { TranslationsObject } from "@genericTypes/sharedTypes";
import Icon from "./call";

interface Props {
    vendor: GQLOffers;
    categoryId: number;
    classNameButton?: string;
    oneLine?: boolean;
    vendorShow?: boolean;
    removeExtraMargin?: boolean;
}

export default function OfferLinks(props: Props): ReactElement {
    const {
        vendor,
        categoryId,
        classNameButton,
        oneLine,
        vendorShow,
        removeExtraMargin,
    } = props;

    const [phoneNumber] = useState(getPhoneNumber(vendor));
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);

    if (phoneNumber) {
        return (
            <div className=" flex flex-col items-center gap-2 ">
                <div className="flex items-center w-full">
                    <div className="grow">
                        <LinkButton
                            href={`/goto/${vendor?.slug ?? ""}/?categoryId=${
                                categoryId
                                    ? categoryId
                                    : (vendor?.categories?.[0]?.id as number) ??
                                      0
                            }&zone=categoryPage`}
                            className={`font-bold   ${
                                styles["linkButtonStyle"]
                            } ${styles["linkButtonStyleWithIcon"]} ${
                                classNameButton ? classNameButton : ""
                            }`}
                            target="_blank"
                        >
                            {translate(
                                translations,
                                "several_general_visitWebsite",
                            )}
                        </LinkButton>
                    </div>
                    <div className="w-14">
                        <LinkButton
                            href={`tel:${phoneNumber}`}
                            className={`font-bold   ${
                                styles["callLinkStyle"]
                            } ${classNameButton ? classNameButton : ""} `}
                            target="_blank"
                        >
                            <Icon />
                        </LinkButton>
                    </div>
                </div>

                <div
                    className={` ${
                        oneLine
                            ? "mb-2"
                            : "mb-2 flex flex-col items-center  sm:block"
                    } `}
                >
                    {!vendorShow && (
                        <Link
                            href={`${resolveURL(vendor.url as GQLUrls)}`}
                            className="  text-xs lg:text-sm"
                        >
                            {translate(
                                translations,
                                "several_general_Read review",
                            )}
                        </Link>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div
            className={`${
                removeExtraMargin ? "mb-2 sm:mb-0" : "mb-2"
            } flex flex-col items-center gap-2 `}
        >
            <>
                <LinkButton
                    href={`/goto/${vendor?.slug ?? ""}/?categoryId=${
                        categoryId
                            ? categoryId
                            : (vendor?.categories?.[0]?.id as number) ?? 0
                    }&zone=categoryPage`}
                    className={`font-bold ${styles["linkButtonStyle"]} ${
                        classNameButton ? classNameButton : ""
                    }`}
                    target="_blank"
                >
                    {translate(translations, "several_general_visitWebsite")}
                </LinkButton>

                {!vendorShow && (
                    <Link
                        href={`${resolveURL(vendor.url as GQLUrls)}`}
                        className="  text-xs lg:text-sm"
                    >
                        {translate(translations, "several_general_Read review")}
                    </Link>
                )}
            </>
        </div>
    );
}
