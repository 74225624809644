// import Link from "next/link";
// import { LinkProps } from "next/link";

type FinalLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    children?: React.ReactNode;
};

const LinkNoPrefetch = ({ children, ...props }: FinalLinkProps) => {
    return <a {...props}>{children}</a>;
};

export default LinkNoPrefetch;
